<adapt-dialog [maxWidth]="850">
    <h1 adapt-dialog-title>End User License Agreement</h1>

    <ng-container adapt-dialog-content>
        <adapt-eula></adapt-eula>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="close"
                                   [hideCancelButton]="true"
                                   (saveClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
