import { ProcessMap } from "./process-map";

export enum ProcessStepType {
    ProcessStep = "ProcessStep",
    RoleTask = "RoleTask",
    ProcessMapLink = "ProcessMapLink",
}

export class ProcessStepTypeMetadata {
    public static readonly IconClass: { [type in ProcessStepType]: string } = {
        [ProcessStepType.ProcessStep]: "fal fa-fw fa-layer-group",
        [ProcessStepType.RoleTask]: "fal fa-fw fa-tasks",
        [ProcessStepType.ProcessMapLink]: ProcessMap.IconClass,
    };

    public static readonly Label: { [type in ProcessStepType]: string } = {
        [ProcessStepType.ProcessStep]: "process step",
        [ProcessStepType.RoleTask]: "role task",
        [ProcessStepType.ProcessMapLink]: "process map link",
    };
}
