import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import * as Sentry from "@sentry/browser";
import { configureSentry } from "./configure-sentry";
import { ILogInstance, ILogProvider } from "./log-provider.interface";
import { Logger } from "./logger";
import { Trace } from "./trace";

export enum SentrySeverity {
    Debug = "debug",
    Info = "info",
    Warning = "warning",
    Error = "error",
}

const sentryTraceMapping: { [level in Trace]: Sentry.SeverityLevel } = {
    [Trace.Debug]: SentrySeverity.Debug,
    [Trace.Info]: SentrySeverity.Info,
    [Trace.Warning]: SentrySeverity.Warning,
    [Trace.Error]: SentrySeverity.Error,
};

export class SentryLogProvider implements ILogProvider {
    public constructor(public readonly logLevel: Trace) { }

    public write(logInstance: ILogInstance) {
        if (logInstance.data[0] instanceof Error) {
            Sentry.captureException(logInstance.data[0]);
            return;
        }

        Sentry.withScope((scope) => {
            scope.setLevel(sentryTraceMapping[logInstance.level]);
            scope.setExtras({
                logData: logInstance.data,
            });
            Sentry.captureMessage(logInstance.message);
        });
    }
}

AdaptClientConfiguration.onInitialisation((config) => {
    configureSentry(config.SentryDsn);
    Logger.registerLogProvider(new SentryLogProvider(config.SentryLevel));
});