import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Bullseye } from "./bullseye";

export enum BullseyeQuadrant {
    PassionateAbout = "PassionateAbout",
    GoodAt = "GoodAt",
    CustomerNeed = "CustomerNeed",
    GetPaidFor = "GetPaidFor",
}

export const BullseyeQuadrantText: { [quadrant in BullseyeQuadrant]: string } = {
    PassionateAbout: "What are you really passionate about?",
    GoodAt: "What are you great at?",
    CustomerNeed: "What do your customers need?",
    GetPaidFor: "What can you be paid for?",
};

const BullseyeOrgNameParam = "ORGANISATION_NAME";
const BullseyeQuadrantQuestionText: { [quadrant in BullseyeQuadrant]: string } = {
    PassionateAbout: `What are you passionate about at ${BullseyeOrgNameParam}?`,
    GoodAt: `At ${BullseyeOrgNameParam}, what are you great at?`,
    CustomerNeed: `What do your customers need from ${BullseyeOrgNameParam}?`,
    GetPaidFor: `At ${BullseyeOrgNameParam}, what can you be paid for?`,
};

export class BullseyeQuadrantStatement extends BaseEntity<BullseyeQuadrantStatement> {
    public bullseyeQuadrantStatementId!: number;
    public organisationId!: number;
    public quadrant!: BullseyeQuadrant;
    public statement!: string;
    public inBullseye?: boolean;

    // navigation
    public bullseye!: Bullseye;

    public static getQuestionTextForQuadrant(quadrant: BullseyeQuadrant, orgName: string) {
        return BullseyeQuadrantQuestionText[quadrant].replace(BullseyeOrgNameParam, orgName);
    }
}

export const BullseyeQuadrantStatementBreezeModel = new BreezeModelBuilder("BullseyeQuadrantStatement", BullseyeQuadrantStatement)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .build();
