import { BaseEntity } from "../../ADAPT.Common.Model/base-entity";

// cast (T | undefined | null) object values to T
type Must<T> = { [P in keyof T]-?: NonNullable<T[P]> }

// get properties of T which are functions, so we can exclude them
type FunctionPropertyNames<T> = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [K in keyof T]: T[K] extends Function ? K : never
}[keyof T];

type Primitive = null | undefined | number | string | boolean | symbol | bigint | Date;

// exclude properties of primitives (e.g. toString)
type ObjectPathExcludePrimitives<T, Key extends keyof T & string, TOut> = T[Key] extends Primitive
    ? Key
    : T[Key] extends Record<string, any>
        ? TOut
        : never;

// exclude array members (length, etc.)
type ExcludeArrayKeys<T> = T extends ArrayLike<any>
    ? Exclude<keyof T, keyof any[]>
    : keyof T;

// exclude breeze specific keys
type ExcludeBreeze<T> = Exclude<keyof T, keyof BaseEntity<T> | "extensions">;
type ExcludedKeys<T> = ExcludeBreeze<T> & ExcludeArrayKeys<T> & Exclude<keyof T, FunctionPropertyNames<T>>;

type ObjectKey<T, Key extends keyof T & string> = ExcludedKeys<T[Key]> & string;
type ObjectKeyPath<T, Key extends keyof T & string> = `${Key}.${ObjectKey<T, Key>}`;
type ObjectPathLevel3<T, Key extends keyof T> = Key extends string
    ? ObjectPathExcludePrimitives<Must<T>, Key, ObjectKeyPath<Must<T>, Key>>
    : never;
type ObjectPathLevel2<T, Key extends keyof T> = Key extends string
    ? ObjectPathExcludePrimitives<Must<T>, Key,
        | `${Key}.${ObjectPathLevel3<T[Key], ObjectKey<T, Key>> & string}`
        | ObjectKeyPath<Must<T>, Key>>
    : never;
type ObjectPathLevel1<T> = ObjectPathLevel2<T, ExcludedKeys<T>> | ExcludedKeys<T>

/**
 * Allows path traversal up to level 3.
 * i.e. `primaryItem.board.teamId` but not `primaryItem.board.team.teamId`.
 */
export type ObjectPath<T> = [T] extends [never]
    ? string
    : keyof T extends string
        ? ObjectPathLevel1<Must<T>> extends infer P
            ? P extends string | keyof T
                ? P
                : keyof T
            : keyof T
        : never;

export class ObjectUtilities {
    public static cleanNullAndUndefinedValues<T extends {[key: string]: any}>(input: T) {
        const data = { ...input };
        const cleanKeys: string[] = [];
        Object.keys(data).forEach((k) => {
            if ((data[k] === undefined) || (data[k] === null)) {
                cleanKeys.push(k);
            }
        });
        cleanKeys.forEach((k) => delete data[k]);

        return data;
    }

    /**
     * Map each key's value to a new value, preserving the original key
     * @param data The object to map each value
     * @param mapper How to map each value.
     */
    public static map<TSource, TTarget, TKey extends string | number | symbol>(data: Record<TKey, TSource>, mapper: (input: TSource) => TTarget) {
        const mapped = Object.entries(data)
            .map(([key, value]) => [key, mapper(value as TSource)]);

        return Object.fromEntries(mapped) as Record<TKey, TTarget>;
    }

    /**
     * Gets a property or sub property by traversing a path of sub properties.
     * If the property isn't found then undefined will be returned
     * NB: Array sub properties are not supported.
     * NB: the 'self' keyword will return the entity itself.
     *
     * @param {Object} object - The object whose property or sub property should be found.
     * @param {string} path - The period (.) delimited path.
     * @returns {any} The property or sub property.
     */
    public static getObjectByPath<T>(object: any, path: string): T | undefined {
        if (path === "self") {
            return object;
        }

        const pathArray = path.split(".");
        let result = object;

        for (const pathValue of pathArray) {
            if (!ObjectUtilities.isObject(result) || !(pathValue in result)) { // in keyword checks prototype chain, traditional hasOwnProperty does not
                // throw error instead?
                return undefined;
            }

            result = result[pathValue];
        }

        return result;
    }

    /**
     * Sets the given value on the given object path. If there is a non object on the path an error
     * will be thrown. If properties are not set on the object path then they will be created.
     * @param object The object to traverse
     * @param path The dot delimited path to traverse
     * @param value The value to set to the last property of the path
     */
    public static setObjectByPath<T>(object: T, path: string, value: any): T {
        const fullPath = path.split(".");
        const leadingPath = fullPath.slice(0, fullPath.length - 1);
        let target = object as any;

        for (const pathValue of leadingPath) {
            if (!ObjectUtilities.isObject(target)) {
                throw new Error("Attempted to set value to path which doesn't exist on object: " + path);
            } else if (!(pathValue in target)) { // in keyword checks prototype chain, traditional hasOwnProperty does not
                target[pathValue] = {};
            }

            target = target[pathValue];
        }

        const targetProperty = fullPath[fullPath.length - 1];
        target[targetProperty] = value;
        return object;
    }

    /**
     * Determines whether a value is not null or undefined
     * @param value : A value to be evaluated
     * @returns A boolean representation of whether the value was not undefined or null
     */
    public static isNotNullOrUndefined(value: any) {
        return !ObjectUtilities.isNullOrUndefined(value);
    }

    /**
     * Determines whether a value is null or undefined
     * @param value : A value to be evaluated
     * @returns A boolean representation of whether the value was undefined or null
     */
    public static isNullOrUndefined<T>(value: T): boolean {
        return typeof value === "undefined" || value === null;
    }

    /**
     * Determines whether a value is defined
     * @param value : A value to be evaluated
     * @returns A boolean representation of whether the value was defined
     */
    public static isDefined<T>(value: T | undefined): value is T {
        return !ObjectUtilities.isUndefined(value);
    }

    /**
     * Determines whether a value is undefined
     * @param value : A value to be evaluated
     * @returns A boolean representation of whether the value was undefined
     */
    public static isUndefined<T>(value: T | undefined): value is undefined {
        return typeof value === "undefined";
    }

    /** Calls the callback for each key/value pair in the object */
    public static forEach<V>(object: {[key: string]: V}, callback: (value: V, key: string) => void) {
        Object.keys(object)
            .forEach((k: string) => callback(object[k], k));
    }

    /**
     * Builds an array by traversing a linked list though the startNode through
     * the specified linkProperty.
     * @param startNode The first node in the linked list
     * @param getNext The property to check for the next node.
     * @param getData The property which will be retrieved to place into
     *      array. If not specified (or not a string) the entire node will be placed into it.
     * @returns An array or each element in the linked list.
     */
    public static linkedListToArray<S, T = S>(
        startNode: S,
        getNext: (curr: S) => S | undefined,
        getData: (curr: S) => T = (curr) => curr as unknown as T,
    ): T[] {
        const array: T[] = [];

        if (!ObjectUtilities.isObject(startNode)) {
            return array;
        }

        let nextNode: S | undefined = startNode;
        while (nextNode) {
            const data = getData(nextNode);
            array.push(data);
            nextNode = getNext(nextNode);
        }

        return array;
    }

    /**
     * This is temporarily here before we move to es2017:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Object/values
     */
    public static values<T>(obj: {[key: string]: T}) {
        const vals: T[] = [];
        for (const key in obj) {
            if (key && obj[key]) {
                vals.push(obj[key]);
            }
        }

        return vals;
    }

    public static assertValueIsEnumKey<T>(value: keyof T, enumType: T) {
        if (typeof enumType[value] === "undefined") {
            throw new Error(`Unknown enum value ${String(value)}`);
        }
    }

    /** Create a filter function which will return true for values with the given type and false otherwise */
    public static createIsInstanceFilter<T>(type: new (...args: any[]) => T) {
        return (obj: any): obj is T => obj instanceof type;
    }

    /** Create a filter function which will return true for values with the given type and throw otherwise */
    public static assertIsInstanceFilter<T>(type: new (...args: any[]) => T) {
        return (obj: any): obj is T => {
            if (!(obj instanceof type)) {
                throw new Error(`Expected type ${type.name}`);
            }
            return true;
        };
    }

    public static isObject(value: any) {
        return value !== null && typeof value === "object";
    }

    /**
     * A function to check if there is exactly one element which is null or undefined.
     * @param {...any} Any number of parameters
     * @returns {boolean} Indicates if there is one parameter which is null or undefined
     */
    public static onlyOneIsNullOrUndefined<T>(...args: T[]) {
        let nullOrUndefinedCount = 0;

        for (const arg of args) {
            if (ObjectUtilities.isNullOrUndefined(arg)) {
                nullOrUndefinedCount++;

                if (nullOrUndefinedCount > 1) {
                    return false;
                }
            }
        }

        return nullOrUndefinedCount === 1;
    }

    /**
     * Coerces undefined to null but leaves every other type of value alone
     * @param {any} value to possibly coerce to null
     * @returns {any|null} value if not undefined, null otherwise.
     */
    public static coerceToNull<T>(value: T) {
        if (ObjectUtilities.isNullOrUndefined(value)) {
            return null;
        }

        return value;
    }

    /**
     * Checks a property or sub property against a provided value by traversing a path of sub properties.
     * NB: Sub properties are supported using Array.prototype.some.
     *
     * @param {Object} object - The object whose property or sub property should be checked.
     * @param {string} path - The period (.) delimited path.
     * @param {Object} value - The value to be compared against.
     * @returns {bool} A boolean comparison of the property or sub property against the provided value.
     */
    public static checkObjectPath(object: any, path: string, value: any) {
        const pathArray = path.split(".");
        let result = object;

        while (pathArray.length) {
            const pathValue = pathArray.shift();

            if (!result || !pathValue || !(pathValue in result)) { // in keyword checks prototype chain, traditional hasOwnProperty does not
                // throw error instead?
                return false;
            }

            result = result[pathValue];

            if (Array.isArray(result)) {
                return result.some(checkRemainingPath);
            }
        }

        return result === value;

        function checkRemainingPath(childObject: object): boolean {
            return ObjectUtilities.checkObjectPath(childObject, pathArray.join("."), value);
        }
    }

    /**
     * Builds an object path based on an indeterminate set of path elements. Any null or undefined
     * elements are stripped prior to path construction.
     * @param {...string} pathElements The elements of the path as individual arguments.
     * @returns {string} The constructed object path.
     */
    public static buildObjectPath(...pathElements: string[]) {
        let paths = Array.prototype.slice.call(pathElements);

        // strip any null or undefined elements
        paths = paths.filter(ObjectUtilities.isNotNullOrUndefined);

        return paths.join(".");
    }
}
