<adapt-theme-test-container label="Fonts and Colors">
    <div id="fonts">
        <h3 class="border-bottom mb-3">Text Sizes &amp; Elements</h3>
        <div class="row">
            <div class="col-lg-6">
                <h1 class="border-0">Heading 1 (h1)</h1>
                <h2>Heading 2 (h2)</h2>
                <h3>Heading 3 (h3)</h3>
                <h4>Heading 4 (h4)</h4>
                <h5>Heading 5 (h5)</h5>
                <h6>Heading 6 (h6)</h6>
            </div>
            <div class="col-lg-6">
                <p>Body Text<br />
                    1234567890<br />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec dui sapien, cursus eget egestas nec, luctus a justo. Sed faucibus nunc leo. Fusce mattis lacus nec
                    urna
                    malesuada, et ullamcorper ex ultrices. Nam eu iaculis lorem. Etiam eleifend porttitor sem, nec aliquam nisi
                    faucibus in. Maecenas massa magna, pharetra vitae euismod non.</p>
            </div>
        </div>

        <h3 class="border-bottom mb-3">Font Weights</h3>
        <div class="row">
            <div class="col-lg-6">
                <p class="large thin">
                    ABCDEFGHIJKLM<br />
                    abcdefghijklm<br />
                    1234567890<br />
                </p>
                <div class="variable">
                    $font-weight-thin;
                </div>
            </div>
            <div class="col-lg-6">
                <p class="large light">
                    ABCDEFGHIJKLM<br />
                    abcdefghijklm<br />
                    1234567890<br />
                </p>
                <div class="variable">
                    $font-weight-light;
                </div>
            </div>
            <div class="col-lg-6">
                <p class="large normal">
                    ABCDEFGHIJKLM<br />
                    abcdefghijklm<br />
                    1234567890<br />
                    <em>ABCDEFGHIJKLM<br />
                        abcdefghijklm</em>
                </p>
                <div class="variable">
                    $font-weight-normal;
                </div>
            </div>
            <div class="col-lg-6">
                <p class="large semibold">
                    ABCDEFGHIJKLM<br />
                    abcdefghijklm<br />
                    1234567890<br />
                </p>
                <div class="variable">
                    $font-weight-semibold;
                </div>
            </div>
            <div class="col-lg-6">
                <p class="large bold">
                    ABCDEFGHIJKLM<br />
                    abcdefghijklm<br />
                    1234567890<br />
                    <em>ABCDEFGHIJKLM<br />
                        abcdefghijklm</em>
                </p>
                <div class="variable">
                    $font-weight-bold;
                </div>
            </div>
        </div>
    </div>

    <div>

        <h3 class="border-bottom mb-3">List Content</h3>
        <div class="row">
            <div class="col-lg-6">
                <h5>Ordered List (default styling)</h5>
                <ol>
                    <li>Element 1 of an ordered list</li>
                    <li>Element 2 of an ordered list</li>
                    <li>Element 3 of an ordered list</li>
                    <li>Element 4 of an ordered list</li>
                </ol>
            </div>
            <div class="col-lg-6">
                <h5>Unordered List (default styling)</h5>
                <ul>
                    <li>Element 1 of an unordered list</li>
                    <li>Element 2 of an unordered list</li>
                    <li>Element 3 of an unordered list</li>
                    <li>Element 4 of an unordered list</li>
                </ul>
            </div>
        </div>
        <div class="row list-content">
            <div class="col-lg-6">
                <h5>Ordered List (list-content styling)</h5>
                <ol>
                    <li>Element 1 of an ordered list</li>
                    <li>Element 2 of an ordered list</li>
                    <li>Element 3 of an ordered list</li>
                    <li>Element 4 of an ordered list</li>
                </ol>
            </div>
            <div class="col-lg-6">
                <h5>Unordered List (list-content styling)</h5>
                <ul>
                    <li>Element 1 of an unordered list</li>
                    <li>Element 2 of an unordered list</li>
                    <li>Element 3 of an unordered list</li>
                    <li>Element 4 of an unordered list</li>
                </ul>
            </div>
        </div>
    </div>

    <div id="colors">
        <h3 class="border-bottom mb-3">Colors</h3>
        <h4 class="border-bottom mb-3">Text Colours</h4>
        <div class="row">
            <adapt-colour-swatch class="col-lg-6"
                                 cssVariablePrefix="bs"
                                 variable="body-color">
                <h5>Primary Text Colour</h5>
                <p>Used for all paragraph text and the panel headings.</p>
                <p>&nbsp;</p>
            </adapt-colour-swatch>

            <adapt-colour-swatch class="col-lg-6"
                                 cssVariablePrefix="bs"
                                 variable="gray-500">
                <h5>Secondary Text Colour</h5>
                <p>Often used for descriptive text alongside a main heading. Font size is always smaller than that of the
                    paragraph.</p>
            </adapt-colour-swatch>
        </div>

        <h4 class="border-bottom mb-3">Bootstrap Base Colours</h4>
        <div class="row">
            <adapt-colour-swatch *ngFor="let color of ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']"
                                 class="col-lg-3"
                                 cssVariablePrefix="bs"
                                 [textColor]="color === 'light' ? '#333' : undefined"
                                 [variable]="color">
                <h5 style="text-transform: capitalize">{{color}}</h5>
            </adapt-colour-swatch>

            <adapt-colour-swatch class="col-lg-6"
                                 variable="primary-bg"
                                 textColor="#333">
                <h5>Primary Background</h5>
            </adapt-colour-swatch>

            <adapt-colour-swatch class="col-lg-6"
                                 cssVariablePrefix="bs"
                                 variable="body-bg"
                                 textColor="#333">
                <h5>Body Background</h5>
            </adapt-colour-swatch>
        </div>

        <h4 class="border-bottom mb-3">Bootstrap Extended Colours</h4>
        <div class="row">
            <adapt-colour-swatch *ngFor="let color of ['blue', 'yellow', 'green',
                                                       'black', 'white', 'gray-100',
                                                       'gray-200', 'gray-300', 'gray-400', 'gray-500',
                                                       'gray-600', 'gray-700', 'gray-800', 'gray-900']"
                                 class="col-lg-3"
                                 cssVariablePrefix="bs"
                                 [textColor]="['white', 'gray-100', 'gray-200', 'gray-300', 'gray-400'].includes(color) ? '#333' : undefined"
                                 [variable]="color">
                <h5 style="text-transform: capitalize">{{color}}</h5>
            </adapt-colour-swatch>
        </div>

        <h4 class="border-bottom mb-3">Bootstrap Alert Colours</h4>
        <div class="row">
            <adapt-colour-swatch *ngFor="let color of ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']"
                                 class="col-lg-3"
                                 [hasSassVariable]="false"
                                 textColorVariable="alert-{{color}}-color"
                                 variable="alert-{{color}}-bg">
                <h5 style="text-transform: capitalize">{{color}}</h5>
            </adapt-colour-swatch>
        </div>
    </div>
</adapt-theme-test-container>
