import { ILogInstance, ILogProvider } from "./log-provider.interface";
import { Trace } from "./trace";

const consoleTraceMapping: { [level in Trace]: (message?: string, ...optionalParams: any[]) => void } = {
    [Trace.Debug]: window.console.debug,
    [Trace.Info]: window.console.log,
    [Trace.Warning]: window.console.warn,
    [Trace.Error]: window.console.error,
};

export class ConsoleLogProvider implements ILogProvider {
    public constructor(public readonly logLevel: Trace) { }

    public write(logInstance: ILogInstance) {
        const logger = consoleTraceMapping[logInstance.level];
        const message = `${logInstance.timestamp.toISOString()}: [Adapt: ${logInstance.moduleId}] ${logInstance.message}`;
        logger(message, ...logInstance.data);
    }
}
