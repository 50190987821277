import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { BaseActiveEntity } from "../base-active-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { UserType } from "../embed/user-type";
import { Person } from "../person/person";
import { ConnectionType } from "./connection-type";
import { Organisation } from "./organisation";
import { Position } from "./position";
import { RoleConnection } from "./role-connection";

export enum RoleInOrganisation {
    Employee = "Employee",
    OwnerLeader = "OwnerLeader",
    Leader = "Leader",
    Other = "Other",
}

export const RoleInOrganisationLabel: { [type in RoleInOrganisation]: string } = {
    [RoleInOrganisation.Employee]: "Employee",
    [RoleInOrganisation.OwnerLeader]: "Owner & leader",
    [RoleInOrganisation.Leader]: "Leader",
    [RoleInOrganisation.Other]: "Other",
};

export class Connection extends BaseActiveEntity<Connection> {
    public connectionId!: number;
    public organisationId!: number;
    public personId!: number;
    public connectionType!: ConnectionType;
    public startDate!: Date;
    public endDate?: Date;
    public hasAccess!: boolean;
    public userType!: UserType;
    public roleInOrganisation!: RoleInOrganisation;

    public organisation!: Organisation;
    public person!: Person;
    public positions!: Position[];
    public roleConnections!: RoleConnection[];

    public isEmployeeConnection = () => this.connectionType === ConnectionType.Employee;
    public isStakeholderConnection = () => this.connectionType === ConnectionType.Stakeholder;
    public isCoachConnection = () => this.connectionType === ConnectionType.Coach;
    public getLatestPosition(): Position {
        if (!this.isEmployeeConnection()) {
            throw new Error("Cant get position of non employee connection");
        }

        return BreezeModelUtilities.getLatestOrActive(this.positions)!;
    }
}

export const ConnectionBreezeModel = new BreezeModelBuilder("Connection", Connection)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .withActivePath("self")
    .withLabelField("person.fullName")
    .withSearchField("concat(person.firstName, concat('' '', person.lastName))")
    .alwaysFetchingNavigationProperty("roleConnections")
    .alwaysFetchingNavigationProperty("positions")
    .alwaysFetchingNavigationProperty("person")
    .build();
