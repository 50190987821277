import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DxValidationGroupComponent } from "devextreme-angular";
import moment, { Moment } from "moment";
import { IdentityService } from "../../identity.service";
import { IdentityUxService, ILoginPayload, IResetPasswordPayload } from "../identity-ux.service";

@Component({
    selector: "adapt-set-initial-password-page",
    templateUrl: "./set-initial-password-page.component.html",
})
export class SetInitialPasswordPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    @ViewChild("validationGroup") public validationGroup: DxValidationGroupComponent | undefined;

    public projectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    public password = "";
    public confirmPassword = "";

    public token?: string;
    public email?: string;
    public expiryDate?: string;
    public organisationName?: string;
    public inviter?: string;
    public redirect?: string;

    public tokenExpiry: Moment;
    public tokenHasExpired = false;

    public isDisabled = true;
    public message?: string;

    public constructor(
        injector: Injector,
        private identityService: IdentityService,
        private identityUxService: IdentityUxService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);

        this.token = this.getSearchParameterValue("token");
        this.email = this.getSearchParameterValue("inviteEmail");
        this.expiryDate = this.getSearchParameterValue("expiryDate");
        this.organisationName = this.getSearchParameterValue("orgName");
        this.inviter = this.getSearchParameterValue("orgPerson");
        this.redirect = this.getSearchParameterValue("welcomeRedirectPath");

        if (!this.token || !this.email) {
            this.tokenHasExpired = true;
        }

        this.tokenExpiry = this.expiryDate
            ? moment(this.expiryDate)
            : moment().add(1, "days");

        this.checkAlreadyRegistered();
    }

    public passwordComparison = () => this.password;

    public ngOnInit() {
        this.notifyActivated();
        this.shellUiService.removeDefaultShellPadding();
    }

    public ngOnDestroy() {
        this.shellUiService.setViewIsLoading(false);
    }

    public validateGroup() {
        this.isDisabled = !this.validationGroup?.instance.validate().isValid;
    }

    public async checkAlreadyRegistered() {
        try {
            const result = await this.identityService.isRegistered({
                email: this.email,
            });

            if (result.body) {
                // user has already set their password
                if (this.redirect) {
                    // redirect them to route specified in url
                    window.location.replace(this.redirect);
                } else {
                    // redrect them to hom
                    this.routeService.gotoHome();
                }
            } else {
                // user has yet to set password, check token is not expired
                this.tokenHasExpired = moment().isAfter(this.tokenExpiry);
            }
        } catch {
            this.tokenHasExpired = true;
        }
    }

    @Autobind
    public async submit($event: MouseEvent) {
        $event.preventDefault();

        if (!this.email || !this.token) {
            this.tokenHasExpired = true;
            return;
        }

        const resetPayload: IResetPasswordPayload = {
            email: this.email,
            newPassword: this.password,
            token: this.token,
        };

        const loginPayload: ILoginPayload = {
            userName: this.email,
            password: this.password,
        };

        try {
            await this.identityUxService.promiseToResetPassword(resetPayload);
            await this.identityUxService.promiseToLogin(loginPayload, this.personalDashboardPageRoute.id);
            this.shellUiService.setViewIsLoading(true);
        } catch (e) {
            if (e === "Invalid token.") {
                e = "Email address is invalid";
            }

            this.message = "Set password failed: " + e;
        }
    }
}
