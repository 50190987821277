import { Injectable, Injector } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";

@Injectable()
export class ConfigurationAuthService {
    public static readonly ManageAccess = "manageAccess";
    public static readonly ConfigureOrganisationBilling = "configureOrganisationBilling";
    public static readonly ConfigureOrganisation = "configureOrganisation";
    public static readonly ChargeOrganisation = "chargeOrganisation";
    public static readonly StakeholderManagerAccess = "stakeholderManagerAccess";

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            ConfigurationAuthService.ManageAccess,
            {
                requirePermissions: FeaturePermissionName.OrganisationAccessManagementConfigure,
            },
        );
        authorisationService.registerAccessVerifier(
            ConfigurationAuthService.ConfigureOrganisationBilling,
            {
                requirePermissions: FeaturePermissionName.OrganisationBillingConfigure,
            },
        );
        authorisationService.registerAccessVerifier(
            ConfigurationAuthService.ConfigureOrganisation,
            {
                requirePermissions: FeaturePermissionName.OrganisationConfigure,
            },
        );
        authorisationService.registerAccessVerifier(
            ConfigurationAuthService.ChargeOrganisation,
            {
                requirePermissions: FeaturePermissionName.PlatformBillingEdit,
            },
        );
        authorisationService.registerAccessVerifier(
            ConfigurationAuthService.StakeholderManagerAccess,
            {
                checkAuthServiceImplementationWithEntity: (injector: Injector) => {
                    const authService = injector.get(AuthorisationService);
                    return authService.promiseToCheckIsStakeholderManager;
                },
            },
        );
    }
}
