import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IConfigGroup, IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { allOrganisationConfigGroups } from "./organisation-configuration-page-elements";


@Component({
    selector: "adapt-organisation-configuration-page",
    templateUrl: "./organisation-configuration-page.component.html",
})
export class OrganisationConfigurationPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly ConfigArea = ConfigArea;

    public configGroups: IConfigGroup[] = [];
    public selectedItem?: IConfigItem;

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
    ) {
        super(injector, elementRef);
        this.configGroups = allOrganisationConfigGroups();
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public hasGenericConfig(area: ConfigArea) {
        return area === ConfigArea.Bullseye
            || area === ConfigArea.StrategicGoals
            || area === ConfigArea.StrategicInputs
            || area === ConfigArea.StrategicAnchors
            || area === ConfigArea.StrategyBoard;
    }
}

export const organisationConfigurationPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-organisation-configuration-page", OrganisationConfigurationPageComponent)
    .atOrganisationUrl("/configuration")
    .withTitle("Organisation Configuration")
    .withSearchKeyword("configure organisation")
    // use billing permission instead of org configure
    // unsubscribed org should still have billing permission so they can subscribe again
    .verifyingAccess(ConfigurationAuthService.ConfigureOrganisationBilling)
    .build();
