<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>{{title}}</h3>
    <ng-container adapt-dialog-content>
        <div role="alert"
             class="alert alert-info">
            <h3>Guidance</h3>
            <p>
                How will people know if they are living this value? Give them some questions that they can ask themselves
                to determine whether they are enacting this value or not. After all, these are values to be lived by, not mere words to be
                posted on your wall. For example:
            </p>
            <blockquote>Did you put yourself in their shoes before responding?</blockquote>
        </div>
        <adapt-html-editor #focus
                           [(contents)]="valueQuestion.question"
                           forceSize="compact"
                           data-test="value-question-input"></adapt-html-editor>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="ok"
                                   [saveIsDisabled]="!valueQuestion.question"
                                   (saveClick)="finish()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
