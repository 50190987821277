import { Component, Inject } from "@angular/core";
import { ValueQuestion } from "@common/ADAPT.Common.Model/organisation/value-question";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { CommonDialogHelper } from "@common/ux/adapt-common-dialog/adapt-common-dialog.helper";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-edit-value-question-dialog",
    templateUrl: "./edit-value-question-dialog.component.html",
})
@PersistableDialog("EditValueQuestionDialog")
export class EditValueQuestionDialogComponent extends BaseDialogComponent<ValueQuestion> {
    public readonly dialogName = "EditValueQuestion";

    public title: string;

    private originalQuestionText: string;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public valueQuestion: ValueQuestion,
    ) {
        super();
        this.originalQuestionText = valueQuestion.question.slice();
        this.title = valueQuestion.entityAspect.entityState.isAdded()
            ? "Add question"
            : "Edit question";
    }

    public finish() {
        this.resolve(this.valueQuestion);
    }

    public cancel() {
        CommonDialogHelper.cancelDialogAndPreserveEntity(
            this.valueQuestion.question === this.originalQuestionText,
            !!this.originalQuestionText,
            () => this.valueQuestion.question = this.originalQuestionText,
            this.valueQuestion,
            () => super.cancel(),
        );
    }
}
