import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { fromEvent, merge } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Autobind } from "../../lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "../../lib/utilities/array-utilities";
import { ObjectUtilities } from "../../lib/utilities/object-utilities";
import { NavigationHierarchyService } from "../../route/navigation-hierarchy.service";
import { INavigationNode } from "../../route/navigation-node.interface";
import { ShellUiService } from "../../shell/shell-ui.service";
import { BaseComponent } from "../../ux/base.component/base.component";
import { ResponsiveService } from "../../ux/responsive/responsive.service";

enum ResponsiveAction {
    Collapse,
    Expand,
}

interface INavigationBreadcrumbsNodes {
    root?: INavigationNode;
    area?: INavigationNode;
    collapsed: INavigationNode[];
    branches: INavigationNode[];
    leafParent?: INavigationNode;
    leaf?: INavigationNode;
    totalCount: number;
}

@Component({
    selector: "adapt-navigation-breadcrumbs",
    templateUrl: "./navigation-breadcrumbs.component.html",
    styleUrls: ["./navigation-breadcrumbs.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBreadcrumbsComponent extends BaseComponent implements OnInit, OnDestroy {
    public nodes: INavigationBreadcrumbsNodes = {
        collapsed: [],
        branches: [],
        totalCount: 0,
    };
    public collapsedTooltip = "";

    private breadcrumbListElement: JQuery;
    private breadcrumbListDomElement: HTMLElement;
    private showOverflowEllipsisClass = "show-ellipsis";

    // See https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    // We use a MutationObserver so that we can detect changes to the DOM outside
    // of the Angular $digest() cycle as collapseBreadcrumbsAsNecessary() does its own
    // digest handling.
    private breadcrumbMutationObserver = new MutationObserver(this.updateBreadcrumbs);

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        private changeDetectorRef: ChangeDetectorRef,
        private navHierarchyFactory: NavigationHierarchyService,
        private responsiveService: ResponsiveService,
        private shellUiService: ShellUiService,
    ) {
        super();

        this.breadcrumbListElement = jQuery(elementRef.nativeElement);
        this.breadcrumbListDomElement = elementRef.nativeElement;
    }

    public ngOnInit() {
        this.observeBreadcrumbs();

        merge(
            fromEvent(window, "resize"),
            this.shellUiService.sidebarState$,
        ).pipe(
            debounceTime(50),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.collapseBreadcrumbsAsNecessary();
        });

        this.navHierarchyFactory.hierarchyChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((i) => {
            this.updateBreadcrumbsIfNecessary(i.node, i.id);
        });

        this.navHierarchyFactory.activeNode$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((node) => {
            this.setBreadcrumbNodesFromNode(node);
        });
    }

    @Autobind
    public ngOnDestroy() {
        this.stopObservingBreadcrumbs();
    }

    private observeBreadcrumbs() {
        // The observer will still track changes after a disconnect() call
        // takeRecords clears all records of changes from the observer
        // We want to do this so that the callback does not immediately fire
        // once we start observing again.
        this.breadcrumbMutationObserver.takeRecords();
        this.breadcrumbMutationObserver.observe(this.breadcrumbListDomElement, {
            childList: true,
        });
    }

    private stopObservingBreadcrumbs() {
        this.breadcrumbMutationObserver.disconnect();
    }

    @Autobind
    private updateBreadcrumbs(mutations: MutationRecord[]) {
        this.log.debug("Breadcrumbs have been updated with the following DOM Changes: ", mutations);

        this.stopObservingBreadcrumbs();
        this.collapseBreadcrumbsAsNecessary();
        this.observeBreadcrumbs();
    }

    @Autobind
    private updateBreadcrumbsIfNecessary(newHierarchyNode: INavigationNode | undefined, hierarchyName: string) {
        if (this.navHierarchyFactory.getActiveHierarchy() === hierarchyName && newHierarchyNode) {
            const activeNode = this.navHierarchyFactory.getActiveNode();

            if (activeNode) {
                this.setBreadcrumbNodesFromNode(activeNode);
            }
        }
    }

    private setBreadcrumbNodesFromNode(node: INavigationNode) {
        const breadcrumbNodes = ObjectUtilities.linkedListToArray(node, (n) => n.parent)
            .reverse()
            .filter((n) => !n.customData.isHiddenInBreadcrumbs);

        for (const n of breadcrumbNodes) {
            this.expandNode(n);
        }

        this.nodes.totalCount = breadcrumbNodes.length;

        // This process breaks down the breadcrumb array into multiple elements
        // to make the process of dynamically hiding nodes when the width is not enough
        // easier to orchestrate.
        // Order is important below! We are going to be hiding the nodes in the inverse order
        // to below.
        // i.e. [1, 2, 3, 4, 5] => (root = 1, area = 2, branches = [3], parent = 4, leaf = 5)
        this.nodes.leaf = ArrayUtilities.removeArrayIndex(breadcrumbNodes, breadcrumbNodes.length - 1);
        this.nodes.leafParent = ArrayUtilities.removeArrayIndex(breadcrumbNodes, breadcrumbNodes.length - 1);
        this.nodes.root = ArrayUtilities.removeArrayIndex(breadcrumbNodes, 0);
        this.nodes.area = ArrayUtilities.removeArrayIndex(breadcrumbNodes, 0);
        this.nodes.branches = breadcrumbNodes; // Splice modifies in place so can just assign left overs
        this.nodes.collapsed = [];

        // Detect changes before collapsing so we start from a known good state
        this.changeDetectorRef.detectChanges();
        this.collapseBreadcrumbsAsNecessary();
    }

    // This method must run outside of Angular's digest handling to correctly collapse
    // all the breadcrumbs.
    @Autobind
    private collapseBreadcrumbsAsNecessary() {
        if (this.responsiveService.currentBreakpoint.isMobileSize) {
            // This (and updateCollapsedTooltip) was running during another digest
            // for an unknown reason. $applyAsync to schedule it for the next digest.
            this.collapseAllNodes();
            this.changeDetectorRef.markForCheck();

            return;
        }

        this.breadcrumbListElement.removeClass(this.showOverflowEllipsisClass);
        this.dynamicallyCollapseBreadCrumbs();

        if (this.allNodesCollapsed()) {
            this.breadcrumbListElement.addClass(this.showOverflowEllipsisClass);
        }

        this.updateCollapsedTooltip();
        this.changeDetectorRef.markForCheck();
    }

    private dynamicallyCollapseBreadCrumbs() {
        const changeHistory: ResponsiveAction[] = [];
        let changesMade = false;
        let iterationCount: any = 0;

        this.log.debug("Dynamically adjusting breadcrumbs. Initial Widths: ", this.getBreadcrumbWidths());

        do {
            const preAdjustScrollWidth = this.breadcrumbListDomElement.scrollWidth;

            // Sanity check, sometime on initial load the offset width will keep changing, throwing
            // this algorithm off. In this case, handle gracefully and exit.
            if (iterationCount > this.nodes.totalCount + 3) {
                this.log.error("Breadcrumbs have not reached equilibrium after " + iterationCount + " iterations");
                changesMade = false;
            } else if (preAdjustScrollWidth > this.breadcrumbListDomElement.clientWidth) {
                this.log.debug("Breadcrumbs are overflowing, collapsing the next node");

                changeHistory.unshift(ResponsiveAction.Collapse);
                changesMade = !!this.executeAndDetectChanges(this.collapseNextNode);

                if (changesMade && this.breadcrumbListDomElement.scrollWidth >= preAdjustScrollWidth) {
                    // only log an error if the new size is actually larger than the original (not equal!)
                    if (this.breadcrumbListDomElement.scrollWidth > preAdjustScrollWidth) {
                        this.log.error("Bad state - after collapsing a breadcrumb node, the width of the breadcrumbs should decrease!"
                            + " Width before collapse: " + preAdjustScrollWidth + ", Width after collapse: "
                            + this.breadcrumbListDomElement.scrollWidth);
                    }

                    changesMade = false;
                }
            } else if (this.atLeastOneNodeIsCollapsed()) {
                this.log.debug("Breadcrumbs fit into available width, expanding a collapsed node");

                changeHistory.unshift(ResponsiveAction.Expand);
                changesMade = !!this.executeAndDetectChanges(this.expandNextNode);
            } else {
                this.log.debug("Breadcrumbs fit into available width and there are no nodes to "
                    + "expand, stopping adjustment");

                changesMade = false;
            }

            iterationCount++;
        } while (changesMade && !this.breadcrumbChangeHistoryIndicatesLoop(changeHistory));

        this.log.debug("Breadcrumb Changes made: ", changeHistory);
        this.log.debug("Breadcrumb adjustment finished. Final Widths: ", this.getBreadcrumbWidths());
    }

    public atLeastOneNodeIsCollapsed() {
        return this.nodes.collapsed.length > 0
            || this.nodeExistsAndIsCollapsed(this.nodes.area)
            || this.nodeExistsAndIsCollapsed(this.nodes.root);
    }

    private allNodesCollapsed() {
        return this.nodes.branches.length === 0
            && this.nodeExistsAndIsCollapsed(this.nodes.area)
            && this.nodeExistsAndIsCollapsed(this.nodes.root);
    }

    private breadcrumbChangeHistoryIndicatesLoop(changeHistory: ResponsiveAction[]) {
        const historyLength = changeHistory.length;

        // We always want to finish on a COLLAPSE action so that the breadcrumbs always
        // fit into the available space, exit early if the last change was an expand
        if (historyLength < 3 || changeHistory[0] === ResponsiveAction.Expand) {
            return false;
        }

        const lastTwoActionsWereDifferent = changeHistory[0] !== changeHistory[1];
        const twoChangesAgoIsTheSameAsMostRecentChange = changeHistory[0] === changeHistory[2];

        return lastTwoActionsWereDifferent && twoChangesAgoIsTheSameAsMostRecentChange;
    }

    @Autobind
    private collapseNextNode() {
        let nodeToCollapse;

        if (this.nodes.branches.length > 0) {
            nodeToCollapse = this.nodes.branches.pop();
            this.nodes.collapsed.push(nodeToCollapse!);
        } else if (this.nodeExistsAndIsNotCollapsed(this.nodes.area)) {
            nodeToCollapse = this.nodes.area;
        } else if (this.nodeExistsAndIsNotCollapsed(this.nodes.root)) {
            nodeToCollapse = this.nodes.root;
        }

        this.collapseNode(nodeToCollapse);

        return nodeToCollapse;
    }

    @Autobind
    private expandNextNode() {
        let nodeToExpand;

        if (this.nodeExistsAndIsCollapsed(this.nodes.root)) {
            nodeToExpand = this.nodes.root;
        } else if (this.nodeExistsAndIsCollapsed(this.nodes.area)) {
            nodeToExpand = this.nodes.area;
        } else if (this.nodes.collapsed.length > 0) {
            nodeToExpand = this.nodes.collapsed.pop();
            this.nodes.branches.push(nodeToExpand!);
        }

        this.expandNode(nodeToExpand);

        return nodeToExpand;
    }

    @Autobind
    private collapseAllNodes() {
        let nodeWasCollapsed = false;

        do {
            nodeWasCollapsed = !!this.collapseNextNode();
        } while (nodeWasCollapsed);
    }

    @Autobind
    private updateCollapsedTooltip() {
        this.collapsedTooltip = "";
        this.collapsedTooltip = this.nodes.collapsed.reduce(this.prependTooltipPartToCurrentTooltipFromNode, this.collapsedTooltip);
        this.collapsedTooltip = this.prependTooltipPartToCurrentTooltipFromNode(this.collapsedTooltip, this.nodes.area);
        this.collapsedTooltip = this.prependTooltipPartToCurrentTooltipFromNode(this.collapsedTooltip, this.nodes.root);
    }

    private prependTooltipPartToCurrentTooltipFromNode(tooltip: string, node?: INavigationNode): string {
        if (!node || !node.customData.isCollapsed) {
            return tooltip;
        } else if (!tooltip) {
            return node.title;
        }

        return node.title + " / " + tooltip;
    }

    private nodeExistsAndIsCollapsed(node?: INavigationNode): boolean {
        return !!node && !!node.customData.isCollapsed;
    }

    public nodeExistsAndIsNotCollapsed(node?: INavigationNode): boolean {
        return !!node && !node.customData.isCollapsed;
    }

    @Autobind
    private expandNode(node?: INavigationNode) {
        if (node) {
            node.customData.isCollapsed = false;
        }
    }

    @Autobind
    private collapseNode(node?: INavigationNode) {
        if (node) {
            node.customData.isCollapsed = true;
        }
    }

    private getBreadcrumbWidths() {
        return {
            scrollWidth: this.breadcrumbListDomElement.scrollWidth,
            availableWidth: this.breadcrumbListDomElement.clientWidth,
        };
    }

    private executeAndDetectChanges<T extends () => any>(fn: T): ReturnType<T> {
        const returnVal = fn();

        // We use $digest instead of $apply as it will only evaluate watchers on this
        // scope rather than the whole application.
        // (see https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$apply)
        this.changeDetectorRef.detectChanges();

        return returnVal;
    }
}
