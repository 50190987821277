import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export enum SystemDocumentType {
    RichText = "RichText",
    ExternalLink = "ExternalLink",
    Image = "Image",
    Video = "Video",
}

export class SystemDocument extends BaseEntity<SystemDocument> {
    public systemDocumentId!: number;
    public organisationId!: number;
    public url!: string;
    public content!: string;
    public type!: SystemDocumentType;

    // navigation
    public organisation!: Organisation;
}

export const SystemDocumentBreezeModel = new BreezeModelBuilder("SystemDocument", SystemDocument)
    .hasSource()
    .withIdField("systemDocumentId")
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();
