import { AfterViewInit, Directive, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxComponent } from "devextreme-angular";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AdaptDialogComponent } from "../adapt-dialog.component/adapt-dialog.component";
import { FocusableElement, IFocusable } from "../focusable";

export enum DialogResolveData {
    NotRequired,
    Required,
}

/** Dialog component will need to extend this class and having template
 * <adapt-dialog>
 *      <h3 adapt-dialog-title>Something</h3>
 *      <div adapt-dialog-content>Contents here</div>
 *      <div adapt-dialog-footer>buttons</div>
 *  </adapt-dialog>
 * @template TInputData The type of the data that will be passed into the dialog using the ADAPT_DIALOG_DATA token
 * @template TResolveData The type of the data that will be returned from this dialog, defaults to the input data
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseDialogComponent<TInputData, TResolveData = TInputData> extends BaseComponent implements AfterViewInit, OnDestroy, IFocusable {
    @ViewChild("focus") protected elementToFocus?: FocusableElement;

    public abstract readonly dialogName: string;

    private resolveData?: TResolveData;
    private finalised$ = new Subject<void>();

    // to indicate to derived dialogs that the dialog is about to close -
    // (this allows these dialog to not respond to entity changes in dialogs that are in the process of closing)
    public isDialogClosing = false;

    // custom dialog implementation is extending this, which then uses <adapt-dialog> component
    // to define what's to be shown in the dialog
    @ViewChild(AdaptDialogComponent) protected popupInstance?: AdaptDialogComponent;
    private queuedDialogInstance = new QueuedCaller<AdaptDialogComponent>();

    private dialogEvents = new Subject<TResolveData>();
    private subscriptions: Subscription[] = [];

    public constructor(protected requiresResolveData: DialogResolveData = DialogResolveData.Required) {
        super();
    }

    public ngAfterViewInit() {
        if (this.popupInstance) {
            this.queuedDialogInstance.setCallee(this.popupInstance);
            // passing visibility changes up where common dialog service will be subscribing to
            this.subscriptions.push(this.popupInstance.visibleEvents.subscribe((e) => this.onVisibleChanged(e)));
            this.subscriptions.push(this.popupInstance.cancelEvents.subscribe(() => this.cancel()));

            if (this.popupInstance.content) {
                this.popupInstance.content.attr("data-dialog", this.dialogName);
            }

            this.subscriptions.push(this.popupInstance.shown.subscribe(() => this.onShown()));
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.completeDialogEvents();
        super.ngOnDestroy();
    }

    public setVisible(visible: boolean) {
        this.queuedDialogInstance.call((popup) => popup.setVisible(visible));
    }

    public getElementToFocus() {
        return this.getElementToFocusRecursive(this.elementToFocus);
    }

    private getElementToFocusRecursive(el: FocusableElement): FocusableElement {
        if (el && "getElementToFocus" in el) {
            // element implements IFocusable - get its chosen element to focus
            const child = el.getElementToFocus();
            return this.getElementToFocusRecursive(child);
        }

        return el;
    }

    public onShown() {
        const el = this.getElementToFocus();

        if (el instanceof DxComponent) {
            // focus DxComponent (e.g. <dx-text-box>)
            el.instance.focus();
        } else if (el instanceof ElementRef) {
            // focus ElementRef (e.g. <input>)
            el.nativeElement.focus();
        }
    }

    private onVisibleChanged(visible: boolean) {
        if (visible || this.dialogEvents.closed) {
            return;
        }

        if (this.requiresResolveData === DialogResolveData.Required && this.resolveData === undefined) {
            throw new Error("ResolveData should have been set before closing dialog");
        }

        this.dialogEvents.next(this.resolveData!);
        this.completeDialogEvents();
    }

    public get events() {
        return this.dialogEvents.asObservable();
    }

    public get isClosed() {
        return this.dialogEvents.closed;
    }

    /**
     * Observable that takes until the dialog resolves or cancels
     */
    public takeUntilDialogFinalised<T>() {
        return takeUntil<T>(this.finalised$);
    }

    /** Close this dialog, notifying subscribers with the optional data */
    public resolve(data: TResolveData) {
        this.isDialogClosing = true;
        this.resolveData = data;
        this.setVisible(false);

        this.finalised$.next();
        this.finalised$.complete();
    }

    /** Close this dialog, not notifying any subscribers */
    public cancel() {
        this.isDialogClosing = true;
        this.completeDialogEvents();
        this.setVisible(false);

        this.finalised$.next();
        this.finalised$.complete();
    }

    /**
     * Resolve this immediately without waiting for AdaptDialogComponent to close
     * This is typically used if the dialog contains another dialog which is not AdaptDialog,
     * e.g. DeleteObjectiveConfirmationDialog uses ConfirmationDialog, which uses AdaptDialog
     * AdaptDialog closed causes close event from ConfirmationDialog to be handled by
     * DeleteObjectiveConfirmationDialog, which will need to call this for the dynamically added
     * dialog element to be cleaned up.
     * Don't need cancelImmediate as it is already completing without waiting for dx dialog close event
     */
    public resolveImmediate(data: TResolveData) {
        this.resolve(data);

        if (!this.dialogEvents.closed) {
            this.dialogEvents.next(this.resolveData!);
            this.completeDialogEvents();
        }
    }

    protected setErrorMessage(message?: string) {
        this.queuedDialogInstance.call((popup) => popup.errorMessage = message);
    }

    private completeDialogEvents() {
        if (!this.dialogEvents.closed) {
            this.dialogEvents.complete();
            // isStopped is deprecated in rxjs 7 - when a subject is completed, it is stopped but not closed until unsubscribe
            // add this so that we can check for closed, which is a replacement for isStopped in the future
            this.dialogEvents.unsubscribe();
        }
    }
}
