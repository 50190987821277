<div class="context-sidebar-container">
    <div class="context-sidebar-wrapper"
         #contentWrapper>
        <dx-scroll-view direction="vertical"
                        [useNative]="true">
            <div class="context-sidebar-content">
                <div class="title-bar d-flex justify-content-between align-items-start gap-3">
                    <button class="btn btn-blank"
                            title="Expand Help"
                            (click)="setIsOpen(true, ContextSidebarState.Fullscreen)"
                            data-test="context-sidebar-fullscreen">
                        <i class="fal fa-fw fa-arrow-left-from-line"></i>
                    </button>

                    <div class="title-bar-content align-self-center text-center"
                         #sidebarTitle
                         data-test="context-sidebar-title"></div>

                    <button class="btn btn-blank"
                            (click)="setIsOpen(false)"
                            data-test="context-sidebar-close">
                        <i class="fal fa-fw fa-times"></i>
                    </button>
                </div>

                <div class="pt-3"
                     #sidebarContent></div>
            </div>
        </dx-scroll-view>
    </div>
</div>

<dx-popup [wrapperAttr]="{class: 'adapt-dialog context-sidebar-popup'}"
          [hideOnOutsideClick]="true"
          [dragEnabled]="true"
          [showTitle]="true"
          [visible]="showPopup"
          [position]="popupPosition"
          height="auto"
          maxHeight="90vh"
          width="90vw"
          (onContentReady)="onPopupContentReady($event)"
          (onHiding)="onHidePopup()"
          shadingColor="rgba(0, 0, 0, 0.5)">
    <div *dxTemplate="let data of 'title'">
        <div class="title-bar d-flex align-items-center">
            <div class="title-bar-content align-items-center flex-grow-1"
                 #popupTitle></div>

            <button *ngIf="(contextSidebarState$ | async) === ContextSidebarState.Fullscreen"
                    class="btn btn-blank"
                    (click)="setIsOpen(true, ContextSidebarState.Open)"
                    data-test="context-sidebar-fullscreen">
                <i class="fal fa-fw fa-arrow-right-to-line"></i>
            </button>

            <button class="btn btn-blank"
                    (click)="setIsOpen(false)"
                    data-test="context-sidebar-close">
                <i class="fal fa-fw fa-times"></i>
            </button>
        </div>
    </div>
    <div *dxTemplate="let data of 'content'"
         class="d-flex flex-column">
        <dx-scroll-view direction="vertical"
                        [useNative]="true">
            <div class="context-sidebar-content p-3"
                 #popupContent></div>
        </dx-scroll-view>
    </div>
</dx-popup>
