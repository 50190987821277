import { Validator } from "breeze-client";
import { BaseActiveEntity } from "../base-active-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Organisation } from "./organisation";

export class CulturalRelationship extends BaseActiveEntity<CulturalRelationship> {
    public culturalRelationshipId!: number;
    public organisationId!: number;
    public culturalLeaderId!: number;
    public personId!: number;
    public primaryOrdinal!: number;
    public secondaryOrdinal!: number;
    public startDate!: Date;
    public endDate?: Date;
    public culturalLeader!: Person;
    public organisation!: Organisation;
    public person!: Person;
}

const validRelationshipValidator = new Validator(
    "validRelationshipValidator",
    (entity: CulturalRelationship) => !!(entity.personId && entity.culturalLeaderId),
    { messageTemplate: "A valid relationship must have a person and a leader defined" },
);

export const CulturalRelationshipBreezeModel = new BreezeModelBuilder("CulturalRelationship", CulturalRelationship)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("culturalLeader")
    .alwaysFetchingNavigationProperty("person")
    .withEntityValidator(validRelationshipValidator)
    .build();
