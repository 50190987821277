import { AdaptError } from "../error-handler/adapt-error";

export class SignalRInvokeError extends AdaptError {
    public static readonly SignalRMessageId = "SignalR";

    public constructor(
        invokedMethod: string,
        public readonly state: SignalR.ConnectionState,
        error: Error,
    ) {
        super(`${SignalRInvokeError.SignalRMessageId}: Failed to invoke "${invokedMethod} when in state ${state}: ${error.message}`);
        this.name = "SignalRInvokeError";
    }
}
