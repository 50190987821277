import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { provideDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { provideUserMenuItem } from "@common/shell/application-bar-user-item-content/user-menu-item";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptArchitectureModule } from "@org-common/lib/architecture/architecture.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { provideMyProfileRoute, providePersonProfileRoute } from "@org-common/lib/directory-shared/person-profile-route-provider";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { AdaptPersonProfileModule } from "@org-common/lib/person-profile/person-profile.module";
import { ProfileUserMenuItem } from "@org-common/lib/person-profile/profile-user-menu-item";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { provideAddPeopleAction } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { AdaptUserManagementSharedModule } from "@org-common/lib/user-management/user-management-shared.module";
import { AdaptWorkflowModule } from "@org-common/lib/workflow/workflow.module";
import { DxDataGridModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { lastValueFrom } from "rxjs";
import { AdaptAccountModule } from "../account/account.module";
import { AddPeopleComponent } from "./add-people/add-people.component";
import { AddPeopleDialogComponent } from "./add-people-dialog/add-people-dialog.component";
import { AltoShellTourPromptDashboardElementComponent } from "./alto-shell-tour-prompt/alto-shell-tour-prompt-dashboard-element.component";
import { EmployeeDirectoryPageComponent, employeeDirectoryPageRoute } from "./employee-directory-page/employee-directory-page.component";
import { PersonDynamicNavigationService } from "./person-dynamic-navigation.service";
import { PersonalDashboardPageComponent } from "./personal-dashboard-page/personal-dashboard-page.component";
import { personalDashboardPageRoute } from "./personal-dashboard-page/personal-dashboard-page.route";
import { myProfilePageRoute, personProfilePageRoute, ProfilePageComponent } from "./profile-page/profile-page.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptLoadingSpinnerModule,
        AdaptOrganisationSharedModule,
        AdaptTwoColumnModule,
        AdaptDashboardElementModule,
        AdaptButtonModule,
        AdaptCommonUserModule,
        AdaptStorageModule,
        AdaptPersonProfileModule,
        AdaptToolbarModule,
        AdaptAuthorisationModule,
        AdaptDirectorySharedModule,
        AdaptArchitectureModule,
        AdaptContextSidebarModule,
        AdaptUserManagementSharedModule,
        AdaptCommonDialogModule,
        AdaptSurveyModule,
        AdaptAccountModule,
        AdaptWorkflowModule,
        AdaptTooltipModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptCommonMeetingsModule,
        AdaptObjectivesModule,
        AdaptKanbanModule,
        DxDataGridModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        AdaptWorkflowModule,
        AdaptLinkDisabledModule,
        AdaptCallToActionModule,
    ],
    declarations: [
        ProfilePageComponent,
        EmployeeDirectoryPageComponent,
        PersonalDashboardPageComponent,
        AddPeopleDialogComponent,
        AddPeopleComponent,
        AltoShellTourPromptDashboardElementComponent,
    ],
    exports: [
        AddPeopleComponent,
    ],
    providers: [
        // doesn't need forRoot for this. I tried importing this module into multiple other modules imported into app.module and still this is loaded up once
        provideUserMenuItem(ProfileUserMenuItem),
        provideDynamicNodeBuilder(PersonDynamicNavigationService),

        provideMyProfileRoute(myProfilePageRoute),
        providePersonProfileRoute(personProfilePageRoute),

        provideAddPeopleAction((injector, config) => {
            const commonDialogService = injector.get(AdaptCommonDialogService);
            return lastValueFrom(commonDialogService.open(AddPeopleDialogComponent, config), { defaultValue: undefined });
        }),
    ],
})
export class AdaptPersonModule {
    public static readonly Routes = [
        ...AdaptUserManagementSharedModule.Routes,
        ...myProfilePageRoute.routes,
        ...personProfilePageRoute.routes,
        ...employeeDirectoryPageRoute.routes,
        ...personalDashboardPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        DirectoryAuthService.registerAccess(authorisationService);
    }
}
