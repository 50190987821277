import { Component, Inject } from "@angular/core";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-edit-team-purpose-dialog",
    templateUrl: "./edit-team-purpose-dialog.component.html",
})
@PersistableDialog("EditTeamPurposeDialog")
export class EditTeamPurposeDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Purpose> {
    public readonly dialogName = "EditTeamPurpose";

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public purpose: Purpose,
    ) {
        super();
        this.autoResolveData = purpose;
    }

    public get entitiesToConfirm() {
        return [this.purpose];
    }

    public get purposeIsUnmodified() {
        return this.purpose.entityAspect.entityState.isUnchanged();
    }
}
