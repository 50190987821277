import { Params } from "@angular/router";
import { ReasonCode } from "@common/identity/ux/identity-ux.service";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { Logger } from "@common/lib/logger/logger";
import { RouteService } from "@common/route/route.service";
import { GuardFailureType, RouteEventsService } from "@common/route/route-events.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ErrorPageRoute } from "@org-common/lib/error-page/error-page.component";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { personalDashboardPageRoute } from "./person/personal-dashboard-page/personal-dashboard-page.route";

const logger = Logger.getLogger("Route Event Handlers");

export function registerRouteEventsHandler(
    routeEventsService: RouteEventsService,
    routeService: RouteService,
    dialogService: AdaptCommonDialogService,
) {
    return () => {
        routeEventsService.guardFailureEvents$.pipe(
            switchMap((e) => {
                switch (e.type) {
                    case GuardFailureType.AuthenticatedGuardFailed:
                        const queryParams: Params = {
                            returnUrl: routeService.stripQueryParams(e.state.url),
                            reasonCode: ReasonCode.requireLogin,
                        };
                        const additionalParamKeys = e.route.queryParamMap.keys;
                        for (const key of additionalParamKeys) {
                            queryParams[key] = e.route.queryParamMap.get(key)!;
                        }

                        if (e.route?.data?.id === loginPageRoute.id) {
                            // already on login page - redirection to error will cause a navigation cancel
                            // - this is to handle 401 when attempting to login which would otherwise ended up with a spinner
                            //   that will never go away
                            return ErrorPageRoute.gotoRoute();
                        } else {
                            return loginPageRoute.gotoRoute(undefined, queryParams);
                        }
                    case GuardFailureType.NoRoute: // default route
                    case GuardFailureType.UnauthenticatedGuardFailed:
                        // needs to logout to access, but since we are logged in, go to home
                        return personalDashboardPageRoute.gotoRoute();
                    case GuardFailureType.ChangeGuardFailed:
                        // change manager decides to stay back - normal flow -> not going to do anything
                        break;
                    case GuardFailureType.OrganisationGuardFailed:
                    case GuardFailureType.FeatureGuardFailed:
                    case GuardFailureType.AccessGuardFailed:
                        const searchParams: Params = {
                            attemptedUrl: routeService.stripQueryParams(e.state.url),
                        };
                        return ErrorPageRoute.gotoRoute(undefined, searchParams);
                    default:
                        logger.log("Unhandled guard", e);
                        break;
                }

                return of(undefined);
            }),
        ).subscribe();

        routeEventsService.navigationEnd$.subscribe((e) => {
            if (routeService.stripQueryParams(e.newUrl) !== routeService.stripQueryParams(e.oldUrl)) {
                if (!routeService.skipDialogCloseAll) {
                    dialogService.closeAll();
                } else { // only skip for a single route change
                    routeService.skipDialogCloseAll = false;
                }
            }
        });
    };
}
