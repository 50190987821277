import { ClientConfiguration } from "./adapt-client-configuration";

export enum BreezeServiceEndpoint {
    Methodology = "Methodology",
    Stakeholder = "Stakeholder",
}

export class ServiceUri {
    public static AccountServiceUri = "http://localhost/Account";
    public static BreezeServiceUri = "http://localhost/Methodology";
    public static MethodologyServicesServiceBaseUri = "http://localhost/MethodologyServices";
    public static StakeholderServicesServiceBaseUri = "http://localhost/StakeholderServices";
    public static PaymentServiceBaseUri = "http://localhost/Payment";
    public static SurveyServiceBaseUri = "http://localhost/Survey";
    public static DeepDiveServiceBaseUri = "http://localhost/DeepDive";
    public static StorageServiceBaseUri = "http://localhost/Storage";
    public static ActivityFeedServiceBaseUri = "http://localhost/ActivityFeed";
    public static SignalRServiceBaseUri = "http://localhost/signalr";
    public static WorkflowRatingBaseUri = "http://localhost/WorkflowRating";

    public static updateFromConfiguration(config: ClientConfiguration) {
        this.AccountServiceUri = `${config.ApiBaseUri}/Account`;
        this.MethodologyServicesServiceBaseUri = `${config.ApiBaseUri}/MethodologyServices`;
        this.StakeholderServicesServiceBaseUri = `${config.ApiBaseUri}/StakeholderServices`;
        this.PaymentServiceBaseUri = `${config.ApiBaseUri}/Payment`;
        this.SurveyServiceBaseUri = `${config.ApiBaseUri}/Survey`;
        this.DeepDiveServiceBaseUri = `${config.ApiBaseUri}/DeepDive`;
        this.StorageServiceBaseUri = `${config.ApiBaseUri}/Storage`;
        this.ActivityFeedServiceBaseUri = `${config.ApiBaseUri}/ActivityFeed`;
        this.SignalRServiceBaseUri = `${config.ApiBaseUri}/signalr`;
        this.WorkflowRatingBaseUri = `${config.ApiBaseUri}/WorkflowRating`;

        this.setBreezeEndpoint(config, BreezeServiceEndpoint.Methodology);
    }

    public static setBreezeEndpoint(config: ClientConfiguration, endpoint: BreezeServiceEndpoint) {
        this.BreezeServiceUri = `${config.ApiBaseUri}/${endpoint}`;
    }
}
