/* eslint-disable max-classes-per-file */
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { Logger } from "@common/lib/logger/logger";
import { ILogger } from "@common/lib/logger/logger.interface";
import { ConnectionEvent } from "./connection-state/connection-event.enum";
import { SignalRService } from "./signalr.service";
import { ISignalRHubImplementation } from "./signalr-hub-implementation.interface";
import { SignalRInvokeError } from "./signalr-invoke-error";
import { SignalRServerHubInvoker } from "./signalr-server-hub-invoker.type";
import { ISignalRSubscriptionHandler } from "./signalr-subscription-handler.interface";

interface IInternalSignalRSubscriptionHandler<TParams extends unknown[]> extends ISignalRSubscriptionHandler<TParams> {
    subscribeToReconnect(): void;
}

export abstract class SignalRHub implements ISignalRHubImplementation {
    protected promiseToInvokeOnServerHub!: SignalRServerHubInvoker;
    private signalRService!: SignalRService;

    protected log: ILogger;

    // Since we need to wait until the signalRService is set before creating these
    // have this queue as implementations of hubs may try to create handlers in their
    // constructor
    private queuedSubscriptionHandlers: IInternalSignalRSubscriptionHandler<unknown[]>[] = [];

    public constructor(serviceName: string) {
        this.log = Logger.getLogger(serviceName);
    }

    public setSignalRService(signalRService: SignalRService) {
        this.signalRService = signalRService;

        this.queuedSubscriptionHandlers.forEach((h) => h.subscribeToReconnect());
        this.queuedSubscriptionHandlers = [];
    }

    public setServerHubInvoker(serverHubInvoker: SignalRServerHubInvoker): void {
        this.promiseToInvokeOnServerHub = serverHubInvoker;
    }

    public abstract getHandlingMethods(): { [key: string]: (...args: any[]) => void; };

    protected createSubscriptionHandler<T extends unknown[]>(subscribeMethod: string, unsubscribeMethod: string): ISignalRSubscriptionHandler<T> {
        const handler = new this.SubscriptionHandler<T>(this, subscribeMethod, unsubscribeMethod);

        if (this.signalRService) {
            handler.subscribeToReconnect();
        } else {
            this.queuedSubscriptionHandlers.push(handler);
        }

        return handler;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private SubscriptionHandler = class <TParams extends unknown[]> implements IInternalSignalRSubscriptionHandler<TParams> {
        private subscriptions = new Set<string>();

        public constructor(
            private hub: SignalRHub,
            private subscribeMethod: string,
            private unsubscribeMethod: string,
        ) {
        }

        public subscribeToReconnect() {
            // Technically this is only required when transitioning between Reconnecting -> Connected
            // (as opposed to ConnectionInterrupted -> Connected) but SignalR will ignore multiple
            // subscriptions which allows us to simplify the logic greatly to just this
            this.hub.signalRService.subscribeToConnectionEvent(ConnectionEvent.Reestablished, () => this.resubscribeAll());
        }

        public promiseToSubscribe(...params: TParams): Promise<void> {
            return this.hub.promiseToInvokeOnServerHub<void>(this.subscribeMethod, ...params)
                .then(() => { this.subscriptions.add(JSON.stringify(params)); })
                .catch((e: SignalRInvokeError) => {
                    throw new this.hub.SignalRHubResubscribeError(e);
                });
        }

        public promiseToUnsubscribe(...params: TParams): Promise<void> {
            this.subscriptions.delete(JSON.stringify(params));
            return this.hub.promiseToInvokeOnServerHub<void>(this.unsubscribeMethod, ...params);
        }

        private resubscribeAll() {
            this.hub.log.info(`SignalR is now connected, resubscribing to ${this.subscribeMethod}`, this.subscriptions);
            this.subscriptions.forEach((k) => {
                const params = JSON.parse(k) as TParams;
                this.promiseToSubscribe(...params);
            });
        }
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private SignalRHubResubscribeError = class extends AdaptError {
        public constructor(private invokeError: SignalRInvokeError) {
            super(`Failed to resubscribe: ${invokeError.message}`);
        }

        public get shouldBeLogged() {
            return this.invokeError.state === SignalR.ConnectionState.Connected;
        }
    };
}
