<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ValuePurposeVision"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-title *ngIf="lastUpdated as updated">
    <adapt-entity-timestamp class="text-muted"
                            [timestamp]="updated"
                            prefix="Last updated"></adapt-entity-timestamp>
</adapt-toolbar-title>
<div adaptToolbarContent
     adaptBlockingButtonGroup>
    <button *ngIf="canEditGuidingPhilosophy$ | async"
            adaptButtonIcon="edit"
            [adaptBlockingClick]="editGuidingPhilosophy"
            data-test="edit-guiding-philosophy-button">Edit purpose &amp; vision</button>
</div>

<div class="row"
     *ngIf="guidingPhilosophyHasData; else noGuidingPhilosophyTemplate">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Purpose &amp; vision</h2>
            </div>
            <div class="card-body">
                <adapt-see-more [maxHeight]="200">
                    <h3>Purpose</h3>
                    <p *ngIf="organisationPurpose?.content as purpose; else noPurposeTemplate"
                       [froalaView]="purpose"
                       data-test="guiding-philosophy-purpose"></p>
                    <ng-template #noPurposeTemplate>
                        <p>No purpose has been defined.</p>
                    </ng-template>
                </adapt-see-more>

                <adapt-see-more [maxHeight]="200">
                    <h3>Vision</h3>
                    <p *ngIf="guidingPhilosophy?.vision as vision; else noVisionTemplate"
                       [froalaView]="vision"
                       data-test="guiding-philosophy-vision"></p>
                    <ng-template #noVisionTemplate>
                        <p>No vision has been defined.</p>
                    </ng-template>
                </adapt-see-more>
            </div>
        </div>
    </div>
    <div class="col-xl-6">
        <div *adaptIfFeatureActive="FeatureName.Bullseye; else bullseyeNotActive"></div>
        <ng-template #bullseyeNotActive>
            <div class="card">
                <div class="card-header">
                    <h2 class="card-title">Bullseye</h2>
                </div>
                <div class="card-body">
                    <p *ngIf="guidingPhilosophy?.bullseye as bullseye; else noBullseyeTemplate"
                       [froalaView]="bullseye"
                       data-test="guiding-philosophy-bullseye"></p>
                    <ng-template #noBullseyeTemplate>
                        <p>No bullseye has been defined.</p>
                    </ng-template>
                </div>
            </div>
        </ng-template>


        <div class="card"
             *ngIf="guidingPhilosophy?.context">
            <div class="card-header">
                <h2 class="card-title">Founding story</h2>
            </div>
            <div class="card-body">
                <adapt-see-more [maxHeight]="200">
                    <div [froalaView]="guidingPhilosophy?.context"
                         data-test="guiding-philosophy-founding-story"></div>
                </adapt-see-more>
            </div>
        </div>
    </div>

    <div class="col-12"
         *ngIf="!!valueStreamsWithPrimedPurpose && valueStreamsWithPrimedPurpose!.length > 0">
        <div class="card">
            <div class="card-header">
                <h2 class="card-title">
                    Value stream purposes
                </h2>
            </div>
            <div class="card-body value-stream-container"
                 data-test="guiding-philosophy-value-streams">
                <div *ngFor="let valueStream of valueStreamsWithPrimedPurpose"
                     class="value-stream-purpose">
                    <h3>{{valueStream.name}}</h3>
                    <div *ngIf="valueStream.purpose?.content as vsPurposeContent; else noVsPurposeTemplate"
                         [froalaView]="vsPurposeContent"></div>
                    <ng-template #noVsPurposeTemplate>
                        No purpose has been defined for this value stream.
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noGuidingPhilosophyTemplate>
    <adapt-call-to-action [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title>Purpose &amp; vision</adapt-call-to-action-title>

        <p>
            Having a clear purpose and well-defined vision helps keep everyone driving
            in the same direction.
        </p>
        <p>
            Your purpose answers the question of why this business exists. It should
            talk to your cause, your passion, what you care about.
        </p>
        <p>
            The point of having a vision is to create something bigger than yourself, something that people can believe in and rally behind.
            A picture of a future that provides meaning and direction.
        </p>

        <button *ngIf="canEditGuidingPhilosophy$ | async"
                adaptCallToActionButton
                adaptButtonIcon="edit"
                [adaptBlockingClick]="editGuidingPhilosophy">
            Edit purpose &amp; vision
        </button>
    </adapt-call-to-action>
</ng-template>
