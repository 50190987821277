import { KeyValue } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { PricingModel } from "@common/ADAPT.Common.Model/embed/pricing-model";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "adapt-display-pricing-breakdown",
    templateUrl: "./display-pricing-breakdown.component.html",
})
export class DisplayPricingBreakdownComponent extends BaseComponent implements OnInit {
    @Input() public pricingModel!: PricingModel;
    @Input() public organisation?: Organisation;

    public readonly UserTypeExtensions = UserTypeExtensions;

    public pricingModelUserCounts = new Map<UserType, number>();

    constructor(private commonDataService: CommonDataService) {
        super();
    }

    public ngOnInit() {
        let connections$: Observable<Connection[]>;
        if (this.organisation) {
            const predicate = new MethodologyPredicate<Connection>("organisationId", "==", this.organisation.organisationId);
            connections$ = this.commonDataService.getByPredicate(ConnectionBreezeModel, predicate);
        } else {
            connections$ = this.commonDataService.getAll(ConnectionBreezeModel);
        }

        const excludedUserTypes = [UserType.None, UserType.Coach];
        const excludedConnectionTypes = [ConnectionType.Coach];
        connections$.pipe(
            map((connections) => connections.filter((conn) => conn.isActive() && !excludedUserTypes.includes(conn.userType) && !excludedConnectionTypes.includes(conn.connectionType))),
        ).subscribe((connections) => {
            for (const connection of connections) {
                const count = this.pricingModelUserCounts.get(connection.userType) ?? 0;
                this.pricingModelUserCounts.set(connection.userType, count + 1);
            }
        });
    }

    public orderByUserOrdinal(a: KeyValue<UserType, number>, b: KeyValue<UserType, number>) {
        return UserTypeExtensions.priceOrdinal(a.key) - UserTypeExtensions.priceOrdinal(b.key);
    }

    public price(userType: UserType) {
        const pricingModel = this.getPricingModelForUserType(userType);
        return pricingModel?.monthlyFeeDollars;
    }

    public subtotal(userType: UserType, value: number) {
        const pricingModel = this.getPricingModelForUserType(userType);
        return pricingModel
            ? pricingModel!.monthlyFeeDollars * value
            : 0;
    }

    public get total() {
        return Array.from(this.pricingModelUserCounts.entries()).reduce((acc, [userType, count]) => {
            return acc + this.subtotal(userType, count);
        }, 0);
    }

    private getPricingModelForUserType(userType: UserType) {
        const pricingModel = this.pricingModel.pricingModelUsers.find((pmu) => pmu.userType === userType);
        return pricingModel;
    }
}
