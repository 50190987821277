import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Anchor, AnchorMetadata } from "@common/ADAPT.Common.Model/organisation/anchor";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { ValidationError, Validator } from "breeze-client";
import { tap } from "rxjs";
import { StrategicAnchorsService } from "../strategic-anchors.service";

@Component({
    selector: "adapt-edit-anchor-dialog",
    templateUrl: "./edit-anchor-dialog.component.html",
    styleUrls: ["./edit-anchor-dialog.component.scss"],
})
export class EditAnchorDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Anchor> implements OnInit {
    public readonly dialogName = "EditAnchor";
    public articleId = AnchorMetadata.articleId;

    protected entitiesToConfirm: Anchor[] = [];

    private readonly duplicateNameError: ValidationError;
    private anchors: Anchor[] = [];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public anchor: Anchor,
        private anchorsService: StrategicAnchorsService,
    ) {
        super(injector);
        this.entitiesToConfirm = [anchor];
        this.autoResolveData = anchor;

        this.duplicateNameError = new ValidationError(
            undefined as unknown as Validator,
            {
                property: anchor.entityType.getProperty("name"),
            },
            "Name already in use",
            "validAnchorName",
        );
    }

    public ngOnInit() {
        this.anchorsService.getAllAnchors().subscribe((anchors) => this.anchors = anchors);
    }

    public get isNew() {
        return this.anchor.entityAspect.entityState.isAdded();
    }

    @Autobind
    public deleteAnchor() {
        return this.anchorsService.deleteAnchor(this.anchor).pipe(
            tap(() => this.resolve(this.anchor)),
            this.takeUntilDestroyed(),
        );
    }

    public nameHasChanged(name: string) {
        this.anchor.name = name;
        if (this.anchors.find((a) => a.entityAspect.entityState.isUnchanged() && a.name === this.anchor.name)) {
            this.anchor.entityAspect.addValidationError(this.duplicateNameError);
        } else {
            this.anchor.entityAspect.removeValidationError(this.duplicateNameError);
        }
    }
}
