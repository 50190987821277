import { Injector, NgZone } from "@angular/core";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { Logger } from "@common/lib/logger/logger";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { IDeferred, PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { Observable, Subject } from "rxjs";
import { IQueryParameterCallbacks } from "../signalr.service";
import { SignalRInvokeError } from "../signalr-invoke-error";
import { ConnectionEvent } from "./connection-event.enum";
import { IConnectionState } from "./connection-state.interface";
import { DisconnectedState } from "./disconnected-state";

export type ConnectionEventHandler = (state: ConnectionEvent) => void;

export interface ISignalRConnectionContext {
    promiseToConnect(): Promise<string>;
    promiseToInvokeServerHubMethod<T>(hub: SignalR.Hub.Proxy, methodName: string, ...args: any[]): Promise<T>;
    disconnect(): void;
    connectionStateChanged$: Observable<ConnectionEvent>;
}

export class SignalRConnectionContext implements ISignalRConnectionContext {
    private static readonly Name = "SignalRConnectionContext";

    public connectionDeferred!: IDeferred<string>;
    public readonly log = Logger.getLogger(SignalRConnectionContext.Name);

    private zone: NgZone;
    private disconnectRequested = false;
    private _state: IConnectionState;

    private connectionStateChanged = new Subject<ConnectionEvent>();
    public connectionStateChanged$ = this.connectionStateChanged.asObservable();

    public constructor(
        public readonly injector: Injector,
        public readonly connection: SignalR.Connection,
        private queryParameterCallbacks: IQueryParameterCallbacks,
    ) {
        this.zone = injector.get<NgZone>(NgZone);

        this.setupConnectionHooks();

        // connectionDeferred set in here
        this._state = new DisconnectedState(this);
    }

    public promiseToConnect() {
        this.state.connect();
        return this.connectionDeferred.promise;
    }

    public promiseToInvokeServerHubMethod<T>(hub: SignalR.Hub.Proxy, methodName: string, ...args: any[]) {
        const deferred = PromiseUtilities.defer<T>();

        this.connectionDeferred.promise.then(() => {
            hub.invoke(methodName, ...args)
                .done((result) => deferred.resolve(result))
                .fail((e) => deferred.reject(new SignalRInvokeError(methodName, this.connection.state, e)));
        });

        return deferred.promise;
    }

    public disconnect() {
        this.disconnectRequested = true;

        this.state.disconnect();
        this.state = new DisconnectedState(this);

        this.disconnectRequested = false;
    }

    public raiseConnectionEvent(eventName: ConnectionEvent) {
        // Force a digest as our SignalR events occur outside of AngularJS
        this.zone.run(() => {
            this.connectionStateChanged.next(eventName);
        });
    }

    public resetConnectionPromise(rejectReason?: string) {
        if (rejectReason) {
            this.log.warn(rejectReason);
            this.connectionDeferred.reject(rejectReason);
        }

        this.connectionDeferred = PromiseUtilities.defer();
    }

    public promiseToStartConnection() {
        const deferred = PromiseUtilities.defer<void>();

        this.connection.start({ transport: ["webSockets"] })
            .done(() => {
                if (this.connection.state === SignalR.ConnectionState.Disconnected) {
                    const error = new AdaptError("SignalR Connection promise resolved but is in the DisconnectedState");
                    deferred.reject(error);
                    return;
                }

                deferred.resolve();
            })
            .fail((error: Error) => deferred.reject(error));

        return deferred.promise;
    }

    public promiseToGetQueryParameters() {
        const queryParameterPromises = ObjectUtilities.map(this.queryParameterCallbacks, (callback) => callback());
        return PromiseUtilities.fromObject(queryParameterPromises);
    }

    public get state() {
        return this._state;
    }

    public set state(newState: IConnectionState) {
        this.log.info(`Leaving ${ConnectionEvent[this._state.event]} state`);
        this.log.info(`Entering ${ConnectionEvent[newState.event]} state`);
        this._state = newState;
        this.raiseConnectionEvent(newState.event);
    }

    private setupConnectionHooks() {
        this.connection.connectionSlow(() => {
            // This may need some more investigation as there doesn't seem to be a "fast connection" event so we
            // can detect the slow connection is no longer (i.e. bad wifi signal has become stronger)
            this.log.info(`Connection to SignalR endpoint ${this.connection.url} has been detected as slow`);
            this.raiseConnectionEvent(ConnectionEvent.Slow);
        });

        this.connection.reconnecting(() => this.state.interruptConnection());
        this.connection.reconnected(() => this.state.reestablishExistingConnection());
        this.connection.disconnected(() => {
            if (!this.disconnectRequested) {
                this.state.startReconnecting();
            }
        });
    }
}
