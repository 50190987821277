import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { Team } from "./team";
import { ValueStream } from "./value-stream";

export class Purpose extends BaseEntity<Purpose> {
    public purposeId!: number;
    public organisationId!: number;
    public teamId?: number;
    public valueStreamId?: number;
    public content?: string;
    public seeds?: string;
    public lastUpdated!: Date;
    public organisation!: Organisation;
    public team!: Team;
    public valueStream!: ValueStream;
}

export const PurposeBreezeModel = new BreezeModelBuilder("Purpose", Purpose)
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();
