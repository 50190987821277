import { BaseConnectionState } from "./base-connection-state";
import { ConnectedState } from "./connected-state";
import { ConnectionEvent } from "./connection-event.enum";
import { DisconnectedState } from "./disconnected-state";
import { SignalRConnectionContext } from "./signalr-connection-context";

export class ConnectingState extends BaseConnectionState {
    public readonly event = ConnectionEvent.Connecting;

    public constructor(context: SignalRConnectionContext) {
        super(context);

        this.context.promiseToGetQueryParameters()
            .then((qp) => this.context.connection.qs = qp)
            .then(() => this.context.promiseToStartConnection())
            .then(() => this.context.state = new ConnectedState(this.context, this))
            .catch((error: Error) => {
                const msg = `Unable to connect to SignalR Endpoint at ${this.signalREndpoint}: ${error.message}`;
                this.context.state = new DisconnectedState(this.context, msg);
            });
    }
}
