import { Injectable, Injector, NgZone, ProviderToken } from "@angular/core";
import { Logger } from "@common/lib/logger/logger";
import { filter } from "rxjs/operators";
import { ConnectionEvent } from "./connection-state/connection-event.enum";
import { ISignalRConnectionContext, SignalRConnectionContext } from "./connection-state/signalr-connection-context";
import { ISignalRHubImplementation } from "./signalr-hub-implementation.interface";

export interface IHubNameToImplementations {
    [hubName: string]: ISignalRHubImplementation[];
}

export interface IQueryParameterCallbacks {
    [queryParam: string]: () => Promise<string | undefined>;
}

export interface IHubNameToImplementationIds {
    [hubName: string]: ProviderToken<ISignalRHubImplementation>[];
}

@Injectable({
    providedIn: "root",
})
export class SignalRService {
    private static readonly HelloEvent = "hello";

    private static signalREndpoint?: string;
    private static detailedLogging = false;
    private static hubNamesAndImplementationConstructors: IHubNameToImplementationIds = {};
    private static promiseToGetQueryParameterLookup: IQueryParameterCallbacks = {};

    private readonly log = Logger.getLogger("SignalRService");

    private connection!: SignalR.Hub.Connection;
    private hubs: SignalR.Hub.Proxy[] = [];
    private signalRConnectionContext!: ISignalRConnectionContext;

    public constructor(
        private injector: Injector,
        private zone: NgZone,
    ) {
        this.connection = $.hubConnection(SignalRService.signalREndpoint, { useDefaultPath: false });
        this.connection.logging = SignalRService.detailedLogging;
        this.signalRConnectionContext = new SignalRConnectionContext(
            this.injector,
            this.connection,
            SignalRService.promiseToGetQueryParameterLookup,
        );
    }

    public get connectionId(): string {
        return this.connection.id;
    }

    public get isConnected(): boolean {
        return this.connection.state === SignalR.ConnectionState.Connected;
    }

    public get connectionStateChanged$() {
        return this.signalRConnectionContext.connectionStateChanged$;
    }

    public static setSignalREndpoint(endpoint: string) {
        this.signalREndpoint = endpoint;
    }

    public static enableDetailedLogging() {
        this.detailedLogging = true;
    }

    public static registerHub(hubName: string) {
        this.initialiseAndGetHubImplementations(hubName);
    }

    /**
     * Register a hub to use with SignalR. This hub must implement ISignalRHubImplementation (or extend SignalRHub)
     * and be registered in AngularJs as a service in order to work successfully.
     * @param hubName
     * @param hubImplementationId
     */
    public static registerHubImplementation(hubName: string, hubImplementationId: ProviderToken<ISignalRHubImplementation>) {
        const implementations = this.initialiseAndGetHubImplementations(hubName);
        implementations.push(hubImplementationId);
    }

    public static registerQueryParameterFactory(queryParam: string, promiseToGetQueryParameter: () => Promise<string | undefined>) {
        this.promiseToGetQueryParameterLookup[queryParam] = promiseToGetQueryParameter;
    }

    private static initialiseAndGetHubImplementations(hubName: string) {
        if (!this.hubNamesAndImplementationConstructors[hubName]) {
            this.hubNamesAndImplementationConstructors[hubName] = [];
        }

        return this.hubNamesAndImplementationConstructors[hubName];
    }

    public setup(hubNameToImplementations?: IHubNameToImplementations) {
        // allow providing hub implementations manually for tests
        if (!hubNameToImplementations) {
            hubNameToImplementations = {};
            const hubNames = Object.keys(SignalRService.hubNamesAndImplementationConstructors);
            for (const hubName of hubNames) {
                hubNameToImplementations[hubName] = SignalRService.hubNamesAndImplementationConstructors[hubName]
                    .map((hubId) => this.injector.get(hubId));
            }
        }

        // Provide default implementation for hello event so that the OnConnected event gets fired on the server
        // See
        // https://docs.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/hubs-api-guide-javascript-client#how-to-establish-a-connection
        // for more details
        this.hubs = this.createHubProxies(Object.keys(hubNameToImplementations));
        this.hubs.forEach((hubProxy) => this.registerHelloCallback(hubProxy));
        this.registerHubProxiesAndHandlers(hubNameToImplementations);
    }

    public promiseToConnect() {
        return this.signalRConnectionContext.promiseToConnect();
    }

    public disconnect() {
        this.signalRConnectionContext.disconnect();
    }

    public subscribeToConnectionEvent(targetEvent: ConnectionEvent, handler: () => void) {
        return this.signalRConnectionContext.connectionStateChanged$.pipe(
            filter((event) => event === targetEvent),
        ).subscribe(() => handler());
    }

    private createHubProxies(hubNames: string[]) {
        return hubNames.map((hubName) => this.connection.createHubProxy(hubName));
    }

    private registerHubProxiesAndHandlers(hubNameAndImplementationConstructors: IHubNameToImplementations) {
        return Object.keys(hubNameAndImplementationConstructors)
            .forEach((hubName) => {
                const hub = this.getHub(hubName);
                this.registerHubImplementations(hub, hubNameAndImplementationConstructors[hubName]);
            });
    }

    private registerHubImplementations(hub: SignalR.Hub.Proxy, implementations: ISignalRHubImplementation[]) {
        for (const implementation of implementations) {
            // We must inject these here as if we use $inject to get the SignalRService then
            // AngularJS will complain about circular dependencies.
            implementation.setServerHubInvoker(this.getPromiseToInvokeHubFn(hub));
            implementation.setSignalRService(this);

            const eventHandlers = implementation.getHandlingMethods();
            Object.keys(eventHandlers)
                .forEach((eventName) => this.onHubEvent(hub, eventName, eventHandlers[eventName]));
        }
    }

    private getPromiseToInvokeHubFn(hub: SignalR.Hub.Proxy) {
        return <T>(methodName: string, ...args: any[]) => this.signalRConnectionContext.promiseToInvokeServerHubMethod<T>(hub, methodName, ...args);
    }

    private registerHelloCallback(hub: SignalR.Hub.Proxy) {
        this.onHubEvent(hub, SignalRService.HelloEvent, () => this.log.info(`Hello from ${hub.hubName}`));
    }

    private onHubEvent(hub: SignalR.Hub.Proxy, event: string, callback: (...args: any[]) => void) {
        hub.on(event, (...args: any[]) => {
            this.zone.run(() => {
                this.log.info(`Handling ${hub.hubName} ${event} with`, args);
                callback(...args);
            });
        });
    }

    private getHub(hubName: string) {
        // SignalR hubNames are case insensitive
        const hub = this.hubs.find((hubProxy) => hubProxy.hubName.toLowerCase() === hubName.toLowerCase());

        if (!hub) {
            throw new Error(`SignalR Hub "${hubName}" was not registered in config`);
        }

        return hub;
    }
}
