import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamMeetingGuidance } from "@common/ADAPT.Common.Model/organisation/team-meeting-guidance";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-configure-team-stewardship",
    templateUrl: "./configure-team-stewardship.component.html",
})
export class ConfigureTeamStewardshipComponent extends BaseComponent implements OnChanges {
    @Input() public team?: Team;

    public teamMeetingGuidance?: TeamMeetingGuidance;

    public constructor(
        private meetingsService: MeetingsService,
    ) {
        super();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.team) {
            await this.initialiseTeamMeetingGuidance(changes.team.currentValue);
        }
    }

    private async initialiseTeamMeetingGuidance(team: Team) {
        if (team.teamMeetingGuidance) {
            this.teamMeetingGuidance = team.teamMeetingGuidance;
        } else {
            // team meeting guidance entity is created from server on team entity creation
            this.teamMeetingGuidance = await lastValueFrom(this.meetingsService.getMeetingGuidanceForTeam(team.teamId));
        }
    }
}
