import { Injectable } from "@angular/core";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { SeedEntity } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { SeedsDialogComponent } from "./seeds-dialog/seeds-dialog.component";

@Injectable({
    providedIn: "root",
})
export class PurposeVisionUiService {
    constructor(private dialogService: AdaptCommonDialogService) { }

    public showOwnersWhy() {
        return this.dialogService.open(SeedsDialogComponent, SeedEntity.Purpose);
    }

    public showOwnersAlignment() {
        return this.dialogService.open(SeedsDialogComponent, SeedEntity.Vision);
    }

    public editPurpose(purpose: Purpose) {
        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, {
            title: `${purpose.content ? "Update" : "Record"} your Purpose statement`,
            articleId: ImplementationKitArticle.PurposeEdit,
            dialogWidth: 1200,
            entities: [{
                entity: purpose,
                fieldName: "content",
                type: SimpleValueType.RichText,
                usePropertyValidator: true,
            }],
            saveOnClose: true,
        } as IEditSimpleValueBreezeEntityDialogData);
    }

    public editVision(vision: GuidingPhilosophy) {
        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, {
            title: `${vision.vision ? "Update" : "Record"} your vision statement`,
            articleId: ImplementationKitArticle.VisionEdit,
            dialogWidth: 1200,
            entities: [{
                entity: vision,
                fieldName: "vision",
                type: SimpleValueType.RichText,
                usePropertyValidator: true,
            }],
            saveOnClose: true,
        } as IEditSimpleValueBreezeEntityDialogData);
    }
}
