import { Component, Injector, OnInit } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { IdentityStorageService } from "@common/identity/identity-storage.service";
import { buildInfo } from "@common/lib/build-info/ADAPT.BuildInfo";
import { SentrySeverity } from "@common/lib/logger/sentry-log-provider";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import * as Sentry from "@sentry/browser";

@Component({
    selector: "adapt-about-page",
    templateUrl: "./about-page.component.html",
})
export class AboutPageComponent extends BaseRoutedComponent implements OnInit {
    public buildDetails = buildInfo;
    public projectLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public storageUri = ServiceUri.StorageServiceBaseUri;

    public constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public sendDiagnosticInformation() {
        const data = {
            totalLocalStorageSizeInBytes: LocalStorage.totalSize,
            adaptLocalStorage: LocalStorage.adaptEntries,
            otherLocalStorageSizesInBytes: LocalStorage.otherEntrySizes,
            buildDetails: this.buildDetails,
        };

        // Remove any sensitive info
        delete data.adaptLocalStorage[IdentityStorageService.AccessTokenKey];

        Sentry.withScope((scope) => {
            scope.setLevel(SentrySeverity.Debug);
            scope.setExtras(data);
            Sentry.captureMessage("Requested Diagnostic Information");
        });

        this.dialogService
            .showMessageDialog("Diagnostic Information Sent", "Please inform the ADAPT Team Member who requested it.")
            .subscribe();
    }

    public clearAdaptData() {
        const keysToExclude = [
            IdentityStorageService.AccessTokenKey,
            IdentityStorageService.UserNameKey,
        ];
        LocalStorage.clearAdaptKeysExcept(keysToExclude);

        this.dialogService
            .showMessageDialog("ADAPT Data Cleared", "You may now continue browsing")
            .subscribe();
    }

    public clearAllLocalData() {
        LocalStorage.clearAll();

        this.dialogService
            .showMessageDialog("Data Cleared", "The page will now reload and you will be required login again")
            .subscribe(() => {
                window.location.pathname = "/";
            });
    }
}
