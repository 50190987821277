import { Component, Inject, Injector } from "@angular/core";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { SUBSCRIBE_ORGANISATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ContentReadyEvent } from "devextreme/ui/accordion";
import { combineLatest, lastValueFrom, Observable } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { IOrganisationCardData } from "../organisation-card/organisation-card.component";

@Component({
    templateUrl: "./my-organisations-page.component.html",
    styleUrls: ["./my-organisations-page.component.scss"],
})
export class MyOrganisationsPageComponent extends BaseRoutedComponent {
    public myOrganisationsData$: Observable<IOrganisationCardData[]>;
    public isStakeholderManager$: Observable<boolean>;
    public canSubscribeOrganisation$: Observable<boolean>;
    public subscribeOrganisationHref$: Observable<string>;

    public constructor(
        userService: UserService,
        authorisationService: AuthorisationService,
        private commonDataService: CommonDataService,
        injector: Injector,
        @Inject(SUBSCRIBE_ORGANISATION_PAGE) subscribeOrganisationPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);

        this.subscribeOrganisationHref$ = subscribeOrganisationPageRoute.getRoute();
        this.isStakeholderManager$ = userService.currentPerson$.pipe(
            switchMap((p) => p ? authorisationService.promiseToCheckIsStakeholderManager() : Promise.resolve(false)),
        );
        this.myOrganisationsData$ = userService.currentPerson$.pipe(
            take(1),
            switchMap(async (p) => {
                if (!p) {
                    return [];
                }

                const [organisations, connections] = await Promise.all([
                    userService.getActiveOrganisationsForCurrentPerson(),
                    this.promiseToGetAllConnections(),
                ]);
                if (organisations && connections) {
                    return organisations
                        .map((org) => this.buildCardData(org, connections))
                        .sort(this.orgDataCompareFn);
                } else {
                    return [];
                }
            }),
            tap(() => this.notifyActivated()),
        );

        this.canSubscribeOrganisation$ = combineLatest([
            userService.currentPerson$,
            this.isStakeholderManager$,
        ]).pipe(
            map(([person, isStakeholderManager]) => {
                return person?.isCoach() || isStakeholderManager;
            }),
        );
    }

    public onAccordionReady(e: ContentReadyEvent) {
        setTimeout(() => e.component.updateDimensions(), 50);
    }

    public buildCardData(organisation: Organisation, allConnections: Connection[]) {
        const cardData: IOrganisationCardData = {
            organisation,
            connectionTypes: [],
        };

        const applicableConnections = allConnections.filter((c) => c.isActive() && c.organisationId === organisation.organisationId);
        for (const type of Object.values(ConnectionType)) {
            if (applicableConnections.some((c) => c.connectionType === type)) {
                cardData.connectionTypes.push(type);
            }
        }

        return cardData;
    }

    private async promiseToGetAllConnections() {
        const transientCommonDataInstance = await this.commonDataService.promiseToCreateTransientCommonDataInstance();

        try {
            const key = "allConnections";
            const options = {
                namedParams: {
                    personalData: true,
                },
            };
            // const connectionBreezeModel: IBreezeModel<Connection> = transientCommonDataInstance.models[ConnectionBreezeModel.identifier];
            return lastValueFrom(transientCommonDataInstance.getWithOptions(ConnectionBreezeModel, key, options));
        } finally {
            transientCommonDataInstance.cleanupInstance?.();
        }
    }

    private orgDataCompareFn(a: IOrganisationCardData, b: IOrganisationCardData) {
        // TODO Do we want something a little more sophisticated here? e.g. employee connections first
        return a.organisation.name.localeCompare(b.organisation.name);
    }
}
