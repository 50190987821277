<div adaptToolbarContent>
    <ng-container *ngIf="selectedSurvey && !compareMode">
        <button adaptButtonIcon="fal fa-fw fa-file-export"
                [adaptBlockingClick]="exportSurvey"
                data-test="export-data-button">Export to Excel</button>
        <button adaptButtonIcon="printBorderless"
                (click)="initialiseElementsForPrint()">Print</button>
    </ng-container>
    <button *ngIf="!hideCompareToolbar && surveys.length > 1"
            adaptButtonIcon="fal fa-fw fa-balance-scale"
            [ngClass]="compareButtonClass"
            (click)="compareClicked(!compareMode)"
            data-test="compare-survey-button">
        Compare
    </button>
</div>

<adapt-toolbar-title *ngIf="surveys.length > 0">
    <div class="d-flex"
         *ngIf="!compareMode; else compareModeButtons">
        <h3 class="align-self-center">Analysing survey</h3>
        <adapt-survey-selection-box class="ms-2 align-self-center"
                                    [surveys]="surveys"
                                    [(survey)]="selectedSurvey"
                                    (surveyChange)="onPrimarySurveyChanged($event)"></adapt-survey-selection-box>
    </div>

    <ng-template #compareModeButtons>
        <div class="d-flex">
            <h3 class="align-self-center">Comparing</h3>
            <adapt-survey-selection-box class="ms-2 align-self-center"
                                        [surveys]="surveys"
                                        [(survey)]="selectedSurvey"
                                        (surveyChange)="onPrimarySurveyChanged($event)"
                                        data-test="compare-from-survey"></adapt-survey-selection-box>
            <h3 class="align-self-center ms-2">with</h3>
            <adapt-survey-selection-box class="ms-2 align-self-center"
                                        [surveys]="surveys"
                                        [(survey)]="selectedSurvey2"
                                        (surveyChange)="onSecondarySurveyChanged($event)"
                                        data-test="compare-to-survey"></adapt-survey-selection-box>
        </div>
    </ng-template>
</adapt-toolbar-title>

<div [ngClass]="compareMode ? 'row': ''"
     *ngIf="surveys.length > 0">
    <adapt-analyse-survey #nonCompareMode
                          [ngClass]="compareMode ? 'col-md-6': ''"
                          [compareMode]="compareMode"
                          [(maxResponseCounts)]="maxResponses"
                          (categorySummaryHeights)="leftHeights = $event"
                          [otherCategorySummaryHeights]="rightHeights"
                          [currentSurvey]="selectedSurvey"
                          [forceInitialise]="forceInitialise"></adapt-analyse-survey>
    <adapt-analyse-survey *ngIf="compareMode"
                          class="col-md-6"
                          [compareMode]="compareMode"
                          [(maxResponseCounts)]="maxResponses"
                          (categorySummaryHeights)="rightHeights = $event"
                          [otherCategorySummaryHeights]="leftHeights"
                          [currentSurvey]="selectedSurvey2"></adapt-analyse-survey>
</div>
<dx-load-panel class="dx-load-panel"
               [shadingColor]="'rgba(0,0,0,0.5)'"
               [position]="{ of: '#content' }"
               [visible]="printLoading$ | async"
               [showIndicator]="true"
               [showPane]="true"
               [message]="'Preparing page for printing…'"
               [shading]="true"
               [hideOnOutsideClick]="false">
</dx-load-panel>
