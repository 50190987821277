import { Injectable } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { DocumentDescriptor } from "../document-descriptor";
import { IStorageProvider, StorageProviderGroup } from "./storage-provider.interface";

@Injectable()
export class CustomUrlProviderService implements IStorageProvider {
    public static readonly ServiceName = "CustomURL";

    public disabled: boolean = false;

    public setupComponent = () => Promise.resolve(); /* Nothing to do */
    public cleanupComponent(): void { /* Nothing to do */ }
    public onOrganisationChanged(): void { /* Nothing to do */ }

    public getName(): string {
        return CustomUrlProviderService.ServiceName;
    }

    public getDisplayName(): string {
        return "Custom URL";
    }

    public getGroupName = () => StorageProviderGroup.CLOUD;

    public getIconClass(): string {
        return "fal fa-fw fa-external-link-alt";
    }

    public getSelectionInProgressText(): string {
        return "Type the custom URL manually";
    }

    @Autobind
    public getDocument(url: string): DocumentDescriptor | null {
        if (url && url.length > 0) {
            let name = "";
            if (UrlUtilities.isUrlAbsolute(url) || /^\/[^/]/.test(url)) { // or start with a single slash (not double)
                name = this.getAbsoluteUrlName(url);
            } else {
                // just show the URL as the name for a relative URL
                name = UrlUtilities.getUrlWithoutQueryString(url);
            }
            const result = new DocumentDescriptor(decodeURIComponent(name), url);
            result.setIconClass(this.getIconClass());
            return result;
        }

        return null;
    }

    public async openChooser(_componentInstanceId: number, data?: DocumentDescriptor): Promise<DocumentDescriptor | null> {
        return data || null;
    }

    private getAbsoluteUrlName(url: string) {
        let name = "";
        const absoluteUrl = UrlUtilities.getAbsoluteUrl(url);
        try {
            const path = new URL(absoluteUrl, window.location.origin);
            const pathSplit = path.pathname.split("/").filter((i) => !!i);

            // take domain + directory for directory
            if (path.pathname.slice(-1) === "/") {
                // only add the hostname if not a relative URL
                if (path.origin !== window.location.origin) {
                    name = path.hostname;
                    // add trailing slash if pathname is set
                    if (pathSplit.length) {
                        name += "/";
                    }
                }
                name += pathSplit.join("/");
            } else {
                // use file name as name
                name = pathSplit.length
                    ? pathSplit[pathSplit.length - 1]
                    : path.hostname;
            }

            // show the full path if it's a file
            if (name === window.location.hostname || path.protocol.toLowerCase() === "file:") {
                name = absoluteUrl;
            }
        } catch {
            name = absoluteUrl;
        }

        return name;
    }
}
