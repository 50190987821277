<table id="breakdown-table">
    <thead>
        <tr>
            <th></th>
            <th class="fw-normal"># of users</th>
            <th class="fw-normal">Subtotal</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let countMap of pricingModelUserCounts | keyvalue: orderByUserOrdinal">
            <td>{{UserTypeExtensions.singularLabel(countMap.key)}} (${{price(countMap.key)}}*)</td>
            <td class="text-end">{{countMap.value}}</td>
            <td class="text-end">${{subtotal(countMap.key, countMap.value)}}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr class="border-top border-secondary">
            <td colspan="2"><strong>Total (ex. GST)</strong></td>
            <td class="text-end"><strong>${{total}}</strong></td>
        </tr>
    </tfoot>
</table>
<p>
    <small>* user prices are per month</small>
</p>
