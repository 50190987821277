<adapt-two-column-page>
    <div left-column>
        <adapt-dashboard-element [name]="title">
            <dx-list [dataSource]="configItems"
                     [grouped]="true"
                     [collapsibleGroups]="true"
                     selectionMode="single"
                     [focusStateEnabled]="false"
                     (onSelectionChanged)="onSelectionChanged($event)"
                     (onContentReady)="onContentReady($event)"
                     data-test="configuration-list">
                <div *dxTemplate="let data of 'group'">
                    <div class="list-group">{{data.key}}</div>
                </div>
                <div *dxTemplate="let data of 'item'">
                    <div class="list-item">{{data.name}}</div>
                </div>
            </dx-list>
        </adapt-dashboard-element>
    </div>

    <div right-column>
        <adapt-dashboard-element name="{{selectedItem.name}}"
                                 *ngIf="selectedItem; else nothingSelectedTemplate">
            <div adapt-dashboard-title-buttons
                 adaptBlockingButtonGroup>
                <button adaptButtonIcon="save"
                        [disabled]="!configurationService.changesAreValid()"
                        [adaptBlockingClick]="saveChanges"
                        class="btn btn-primary"
                        data-test="save-config-button">Save</button>
                <button adaptButtonIcon="cancel"
                        [disabled]="!configurationService.hasChanges()"
                        [adaptBlockingClick]="cancelChanges">Cancel</button>
            </div>
            <div role="alert"
                 class="alert alert-danger"
                 *ngIf="saveError">{{saveError}}
            </div>

            <ng-content></ng-content>
        </adapt-dashboard-element>
        <ng-template #nothingSelectedTemplate>
            <div class="card-body">Please select an area on the left to configure</div>
        </ng-template>
    </div>
</adapt-two-column-page>
