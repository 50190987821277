import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { InputType } from "./input";
import { InputGroupType } from "./input-group";

export class InputTypeMetadataClass {
    public constructor(
        public articleId: ImplementationKitArticle,
        public singularLabel: string,
        public pluralLabel: string,
        public iconClass: string,
        public dialogLabel?: string,
    ) { }
}

export const InputTypeMetadata: { [inputType in InputType]: InputTypeMetadataClass } = {
    [InputType.Strength]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputStrength, "Strength", "Strengths", "fal fa-fw fa-bolt"),
    [InputType.Weakness]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputWeakness, "Weakness", "Weaknesses", "fal fa-fw fa-circle-exclamation"),
    [InputType.Trend]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputTrend, "Trend", "Trends", "fal fa-fw fa-chart-line"),

    [InputType.Stop]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputSSKDTodo, "Stop", "Stops", "fal fa-fw fa-question"),
    [InputType.Start]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputSSKDTodo, "Start", "Starts", "fal fa-fw fa-question"),
    [InputType.KeepDoing]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputSSKDTodo, "Keep Doing", "Keep Doings", "fal fa-fw fa-question"),

    [InputType.Win]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAnalysisWin, "Win", "Wins", "fal fa-fw fa-crown", "Why do they win"),
    [InputType.Lose]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAnalysisLose, "Lose", "Loses", "fal fa-fw fa-thumbs-down", "Why do they lose"),
    [InputType.Compete]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAnalysisCompete, "Compete", "Competes", "fal fa-fw fa-hand-fist", "Where do they compete"),
};

export const InputGroupTypeMetadata: { [inputGroupType in InputGroupType]: InputTypeMetadataClass } = {
    [InputGroupType.Competitor]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAddIntroduction, "Competitor", "Competitors", "fal fa-fw fa-user-check"),
};
