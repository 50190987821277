import { InjectionToken, Type } from "@angular/core";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { Observable } from "rxjs";

export const SIDEBAR_TABS = new InjectionToken<ISidebarTab[]>("SIDEBAR_TABS");

export enum SidebarTabPosition {
    Top,
    Bottom
}

export interface ISidebarTab {
    id: string;
    label: string;
    ordinal: number;
    maxWidth: number;
    position?: SidebarTabPosition;
    icon?: IComponentRender<unknown>;
    content?: IComponentRender<unknown>;

    isActive$?: Observable<boolean>;
    isLoading$: Observable<boolean>;
    isDisplayed$: Observable<boolean>;
    focusTab$: Observable<void>;
    // if you call preventDefault on the event, the tab will not be opened on click
    onClick?: (event: CustomEvent) => Observable<any>;
}

export function provideSidebarTab(tab: Type<ISidebarTab>) {
    return {
        provide: SIDEBAR_TABS,
        useClass: tab,
        multi: true,
    };
}
