<adapt-dialog [maxWidth]="1200">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <adapt-configure-team-stewardship [team]="team"></adapt-configure-team-stewardship>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"
                                   [saveIsDisabled]="disabled">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
