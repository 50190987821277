<adapt-dialog [maxWidth]="720"
              (shown)="itemNameComponent.instance.focus()">
    <h3 adapt-dialog-title>Edit meeting agenda item</h3>
    <ng-container adapt-dialog-content>
        <div class="d-flex flex-column">
            <div class="d-flex flex-column flex-md-row">
                <div class="align-self-baseline d-flex flex-column flex-grow-1 w-100 me-3">
                    <h3 class="ms-1 mb-1">Name</h3>
                    <dx-text-box #agendaItemName
                                 placeholder="Type the new meeting agenda item name"
                                 [(value)]="data.agendaItem.name"
                                 [adaptValidateEntity]="data.agendaItem"
                                 adaptValidateEntityProperty="name"></dx-text-box>
                </div>

                <div class="align-self-baseline d-flex flex-column">
                    <h3 class="mt-3 ms-1 mb-1">Duration</h3>
                    <div class="d-flex flex-row">
                        <dx-number-box class="align-self-center"
                                       [min]="0"
                                       [max]="360"
                                       [width]="90"
                                       [(value)]="data.agendaItem.plannedDurationInMinutes"
                                       data-test="agenda-item-duration-input"></dx-number-box>
                        <span class="ms-2 align-self-center">minutes</span>
                    </div>
                </div>
            </div>

            <h3 class="mt-3 ms-1 mb-1">Description</h3>
            <ng-container *adaptLoading="!data.agendaItem.supplementaryData">
                <adapt-html-editor forceSize="compact"
                                   placeholderText="Type the agenda item description"
                                   [(contents)]="data.agendaItem.supplementaryData!.itemDescription"
                                   data-test="agenda-item-description-input"></adapt-html-editor>
            </ng-container>
        </div>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveBlockingClick]="onClosed"
                                   [saveButtonPreset]="data.saveOnClose ? 'saveAndClose' : 'ok'"
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>
