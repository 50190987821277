import { INameValueEntity } from "@common/ADAPT.Common.Model/nameValueEntity.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { Validator } from "breeze-client";
import { Account } from "../account/account";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Connection } from "./connection";
import { CulturalLeadership } from "./cultural-leadership";
import { EventCadenceCycle } from "./event-cadence-cycle";
import { Meeting } from "./meeting";
import { OrganisationDetail } from "./organisation-detail";
import { OrganisationSupplementaryData } from "./organisation-supplementary-data";
import { ValueStream } from "./value-stream";

export class Organisation extends BaseEntity<Organisation> {
    public organisationId!: number;
    public name!: string;
    public website?: string;
    public country?: string;
    public imageIdentifier?: string;
    public urlIdentifier!: string;
    public accounts!: Account[];
    public connections!: Connection[];
    public culturalLeadership!: CulturalLeadership;
    public eventCadenceCycle!: EventCadenceCycle;
    public meetings!: Meeting[];
    public organisationDetails!: OrganisationDetail[];
    public supplementaryData!: OrganisationSupplementaryData;
    public valueStreams!: ValueStream[];

    public getDetailRecord(detailName: string) {
        return this.getKeyValueRecord(this.organisationDetails, detailName) as OrganisationDetail | undefined;
    }

    public getDetailValue(detailName: string) {
        return this.getKeyValueValue(this.organisationDetails, detailName);
    }

    public getAccount() {
        return this.accounts.length > 0
            ? this.accounts[0]
            : undefined;
    }

    public getActiveEmployeeConnectionCount() {
        let activeEmployeeConnectionCount = 0;

        this.connections.forEach(checkConnection);

        return activeEmployeeConnectionCount;

        function checkConnection(connection: Connection) {
            if (connection.isActive() && connection.isEmployeeConnection() && connection.hasAccess) {
                activeEmployeeConnectionCount++;
            }
        }
    }

    private getKeyValueRecord(entities: INameValueEntity[], name: string) {
        if (!entities) {
            return undefined;
        }

        return ArrayUtilities.getSingleFromArray(entities.filter(matchName));

        function matchName(entity: INameValueEntity) {
            return entity.name === name;
        }
    }

    private getKeyValueValue(entities: INameValueEntity[], name: string) {
        const record = this.getKeyValueRecord(entities, name);
        if (!record) {
            return undefined;
        }

        return record.value;
    }
}

const partialUrlValidator = Validator.makeRegExpValidator(
    "partialUrlValidator",
    /^[a-z0-9-]+$/, // regular expression restricts values to only lower case alphas and numerics
    'The %displayName% "%value%" is not a valid partial url',
);
export const OrganisationBreezeModel = new BreezeModelBuilder("Organisation", Organisation)
    .withIdField("organisationId")
    .withLabelField("name")
    .withSortField("name")
    .alwaysFetchingNavigationProperty("organisationDetails")
    .hasSource()
    .withPropertyValidator("urlIdentifier", partialUrlValidator)
    .build();
