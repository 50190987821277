import { Component } from "@angular/core";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-update-invoice-payment-method-dialog",
    templateUrl: "./update-invoice-payment-method-dialog.component.html",
})
export class UpdateInvoicePaymentMethodDialogComponent extends BaseDialogComponent<void>{
    public readonly dialogName = "UpdateInvoicePaymentMethodDialog";

    public constructor() {
        super(DialogResolveData.NotRequired);
    }
}
