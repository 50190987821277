<ng-container *ngIf="runData.meeting as meeting">
    <h3>Title</h3>
    <dx-text-box [value]="meeting.name"
                 [readOnly]="true"></dx-text-box>

    <ng-container *ngIf="meeting.supplementaryData">
        <h3 class="mt-3">Description</h3>
        <adapt-html-editor [contents]="meeting.supplementaryData.purpose"
                           (contentsChange)="onDescriptionChange($event)"
                           [readonly]="submitting"
                           forceSize="compact"
                           placeholderText="Enter an optional description. For example, use this to describe any desired outcomes of the meeting or prep work you may wish for attendees to complete."
                           data-test="meeting-description"></adapt-html-editor>
    </ng-container>

    <h3 class="mt-3">Agenda</h3>
    <div *ngIf="agendaIsReadonly"
         class="alert alert-info">
        The agenda for this meeting can only be modified from the live meeting sidebar or page.
    </div>

    <div *ngIf="agendaIsLocked"
         class="d-flex alert alert-warning align-items-center">
        The agenda for this meeting is read-only as the meeting has ended.
        <button class="btn btn-link ms-auto"
                adaptButtonIcon="fal fa-money-check-edit"
                [adaptBlockingClick]="allowAgendaEdit">Allow editing</button>
    </div>

    <adapt-display-agenda-items *ngIf="agendaIsReadonly || agendaIsLocked; else editableAgenda"
                                [agendaItems]="meeting.meetingAgendaItems"></adapt-display-agenda-items>
    <ng-template #editableAgenda>
        <adapt-edit-agenda [meeting]="meeting"
                           [disabled]="submitting"
                           [saveOnChange]="false"
                           (isAddingNewAgendaItem)="addingNewAgendaItem = $event"
                           (agendaItemsChange)="onAgendaItemsChange($event)"></adapt-edit-agenda>
    </ng-template>
</ng-container>
