import { HttpErrorResponse } from "@angular/common/http";
import { BreezeSaveError } from "../data/errors/breeze-save-error";

export class ErrorHandlingUtilities {
    private static readonly unauthorisedMessage = "Unauthorised request.";
    private static readonly unknownErrorMessage = "Unknown error.";
    private static readonly forbiddenMessage = "Sorry, you do not have the necessary permissions to save these changes";

    /**
     * This function parses the standard IHttpActionResult message we get back from our WebAPI controllers.
     * It just gets the first message it finds.
     * todo: This function needs to be consolidated with our webapi.  Our webapis checked to all return a common format
     * todo: At the moment, its a mess because different calls return results in slightly different structures.
     *
     * @param {Object} response - The 'data' parameter coming back from the controller call.
     * @return {string} Response Message
     */
    public static getHttpResponseMessage(response: any): string {
        if (!response) {
            // todo: how do we even get in here?
            return ErrorHandlingUtilities.unknownErrorMessage;
        }

        if (typeof response === "string") {
            return response;
        }

        if (response instanceof BreezeSaveError) {
            if (response.saveError.status === 403) {
                return ErrorHandlingUtilities.forbiddenMessage;
            }
        }

        if (response instanceof HttpErrorResponse) {
            // see here for examples of types of responses: https://angular.io/guide/http#error-handling
            const httpErrorResponse = response as HttpErrorResponse;

            if (httpErrorResponse.status === 403) {
                return ErrorHandlingUtilities.unauthorisedMessage;
            }

            if (httpErrorResponse.error && httpErrorResponse.error.Message) {
                return httpErrorResponse.error.Message;
            }
        }

        if (response instanceof XMLHttpRequest) {
            const request = response as XMLHttpRequest;

            if (typeof request.response === "string") {
                try {
                    const data = JSON.parse(request.response);

                    if (data.Message) {
                        return data.Message;
                    }
                } catch {
                    return request.response;
                }
            }
        }

        // the info is at different locations depending on where it came from (see to do above)
        const responseToCheck = response.data
            ? response.data
            : response;

        if (responseToCheck) {
            if (responseToCheck.ModelState) {
                const error = ErrorHandlingUtilities.getModelStateError(responseToCheck.ModelState);

                if (error) {
                    return error;
                }
            }

            if (responseToCheck.EntityErrors || responseToCheck.entityErrors) {
                const error = getEntityErrors(responseToCheck.EntityErrors || responseToCheck.entityErrors);

                if (error) {
                    return error;
                }
            }

            if (responseToCheck.error_description) {
                return responseToCheck.error_description;
            }

            if (responseToCheck.ExceptionMessage) {
                return responseToCheck.ExceptionMessage;
            }

            if (responseToCheck.Message) {
                return responseToCheck.Message;
            }

            if (responseToCheck.message) {
                return responseToCheck.message;
            }
        }

        return ErrorHandlingUtilities.unknownErrorMessage;

        function getEntityErrors(entityErrors: any) {
            if (!Array.isArray(entityErrors)) {
                return undefined;
            }

            let error = "";
            for (const entityError of entityErrors) {
                error += entityError.ErrorMessage || entityError.errorMessage;
            }

            return error;
        }
    }

    public static getModelStateError(modelState: any) {
        if (!modelState) {
            return undefined;
        }

        for (const key in modelState) {
            if (modelState.hasOwnProperty(key)) {
                if (modelState[key].length > 0) {
                    const firstError = modelState[key][0];

                    return firstError;
                }
            }
        }

        return undefined;
    }
}
