import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { Logger } from "../lib/logger/logger";
import { LocalStorage } from "../lib/storage/local-storage";
import { StorageLimitReachedError } from "../lib/storage/storage-limit-reached-error";

export interface ISessionData {
    userName: string;
    access_token: string;
}

// Needs this to be in a separate service than identity.service to avoid cyclic dependency
// $http <- ADAPT.Common.Identity <- ADAPT.Common.Identity.Interceptor <- $http <- $templateRequest <- $route
@Injectable({
    providedIn: "root",
})
export class IdentityStorageService {
    public static readonly AccessTokenKey = "accessToken";
    public static readonly UserNameKey = "userName";

    private readonly log = Logger.getLogger(this.constructor.name);

    private _identity$: BehaviorSubject<ISessionData | undefined>;

    public constructor(
    ) {
        this._identity$ = new BehaviorSubject(this.sessionData);
    }

    public get sessionData(): ISessionData | undefined {
        const sessionData = {
            access_token: this.accessToken,
            userName: this.userName,
        };

        if (sessionData.access_token && sessionData.userName) {
            return sessionData as ISessionData;
        } else {
            return undefined;
        }
    }

    public set sessionData(sessionData: ISessionData | undefined) {
        if (sessionData) {
            this.trySetSessionData(sessionData);
        } else {
            LocalStorage.delete(IdentityStorageService.AccessTokenKey);
            LocalStorage.delete(IdentityStorageService.UserNameKey);
        }

        this._identity$.next(sessionData);
    }

    public clearSessionData() {
        this.sessionData = undefined;
    }

    public get accessToken() {
        return LocalStorage.get<string>(IdentityStorageService.AccessTokenKey);
    }

    public get isLoggedIn() {
        return LocalStorage.containsKey(IdentityStorageService.UserNameKey);
    }

    public get userName() {
        return LocalStorage.get<string>(IdentityStorageService.UserNameKey);
    }

    /** Returns a hot observable that emits with the current identity then each time the current
     * user identity changes
     */
    public get identity$() {
        return this._identity$.asObservable();
    }

    public get identityChangedInAnotherTab$() {
        return LocalStorage.dataModifiedInAnotherTab(IdentityStorageService.AccessTokenKey).pipe(
            map((e) => ({ isLoggedIn: !!e.newValue })),
        );
    }

    private trySetSessionData(sessionData: ISessionData) {
        try {
            this.setSessionData(sessionData);
        } catch (e) {
            if (!(e instanceof StorageLimitReachedError)) {
                throw e;
            }

            // To eliminate a rogue 3rd party filling up LocalStorage, clear it and try again
            this.log.info("Session Data failed to write on first attempt, clearing and attempting again");
            LocalStorage.clearAll();
            this.setSessionData(sessionData);
            this.log.info("Session Data successfully set after clearing LocalStorage");
        }

    }

    private setSessionData(sessionData: ISessionData) {
        LocalStorage.setStrict(IdentityStorageService.AccessTokenKey, sessionData.access_token);
        LocalStorage.setStrict(IdentityStorageService.UserNameKey, sessionData.userName);
    }
}
