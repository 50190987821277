import { Component, Inject, Injector } from "@angular/core";
import { Input as StrategicInput } from "@common/ADAPT.Common.Model/organisation/input";
import { InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { switchMap, tap } from "rxjs";
import { StrategicInputsService } from "../strategic-inputs.service";

@Component({
    selector: "adapt-edit-input-dialog",
    templateUrl: "./edit-input-dialog.component.html",
    styleUrls: ["./edit-input-dialog.component.scss"],
})
export class EditInputDialogComponent extends BaseDialogWithDiscardConfirmationComponent<StrategicInput> {
    public readonly dialogName = "EditInput";
    public articleId: ImplementationKitArticle;
    public inputTypeLabel: string;

    protected entitiesToConfirm: IBreezeEntity<any>[];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public input: StrategicInput,
        private dialogService: AdaptCommonDialogService,
        private strategicInputsService: StrategicInputsService,
    ) {
        super(injector);
        this.entitiesToConfirm = [input];
        this.autoResolveData = input;
        this.articleId = InputTypeMetadata[input.type].articleId;
        this.inputTypeLabel = InputTypeMetadata[input.type].dialogLabel ?? InputTypeMetadata[input.type].singularLabel;
    }

    public get isNew() {
        return this.input.entityAspect.entityState.isAdded();
    }

    @Autobind
    public deleteInput() {
        const dialogData: IConfirmationDialogData = {
            title: `Deleting ${this.inputTypeLabel}...`,
            message: `<p>You are about to delete ${this.inputTypeLabel} with name:</p>
                <blockquote><b>${this.input.name}</b></blockquote>
                <p>All associated link to zone and theme will be deleted. If you choose to delete, they can no longer be recovered.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Delete",
            cancelButtonText: "Cancel",
            checkboxMessage: "Check here to confirm that you want to delete the input permanently",
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            // don't have to delete input locations as InputRepositoryEntity will handle that and return deleted associated entities
            // in saveMap to clean up our breeze manager cache
            switchMap(() => this.strategicInputsService.remove(this.input)),
            switchMap(() => this.strategicInputsService.saveEntities(this.input)),
            tap(() => this.resolve(this.input)),
        );
    }
}
