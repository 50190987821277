<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>{{dialogTitle}}</h3>
    <ng-container adapt-dialog-content>
        <form role="form"
              name="selectDocumentForm">
            <div class="form-group row">
                <label class="col-md-2 form-label"
                       for="linkName">Text:</label>
                <div class="col-md-10">
                    <dx-text-box id="linkName"
                                 [(value)]="name"
                                 (onValueChanged)="onValueChange($event)"
                                 placeholder="Enter text for the hyperlink"></dx-text-box>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 form-label"
                       for="selectDocumentLink">Link:</label>
                <adapt-select-document class="col-md-10"
                                       id="selectDocumentLink"
                                       [selectedDocument]="selectedDoc"
                                       (selectedDocumentChange)="onSelectedDocumentChange($event)"></adapt-select-document>
            </div>
        </form>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="ok"
                                   [saveIsDisabled]="!selectedDoc?.getUrl()"
                                   (saveClick)="ok()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
